import styled, { css } from 'styled-components';
import { CardContainerDefaultStyles } from 'components/atoms/CardContainer/CardContainer';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';

export const StyledClientForm = styled.div`
  padding-bottom: 10rem;
  gap: 5rem;
  display: flex;
  flex-direction: column;
`;

interface SubunitStyleProps {
  $isActive?: boolean;
}

export const StyledCardContainer = styled.div<SubunitStyleProps>`
  margin-bottom: 5rem;
  ${CardContainerDefaultStyles}
  background: ${({ theme }) => theme.color.greyB};
`;

export const StyledCardContainerToggle = styled.div<SubunitStyleProps>`
  margin-top: 5rem;
  margin-bottom: 5rem;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      ${CardContainerDefaultStyles}
      background: ${theme.color.greyB};
    `}
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 2rem;
`;
