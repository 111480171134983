import { ReactElement, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './AssessmentDashboard.style';
import { Tabs } from 'components/atoms/Tabs/Tabs';
import { EmptyDashboard } from 'components/molecules/EmptyDashboard/EmptyDashboard';
import { RootState } from 'redux/store';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';

interface AssessmentDashboardProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
}

export const AssessmentDashboard = ({
  title,
  subtitle,
  children,
}: AssessmentDashboardProps): ReactElement => {
  const { cohorts, currentCohortIndex } = useSelector((state: RootState) => state.dashboard);

  const dispatch = useDispatch();

  const assessmentNames: Array<string> = useMemo(
    () => cohorts?.map((cohort) => cohort.cohort_survey.survey.name) || [],
    [cohorts],
  );

  const handleAssessmentTabChange = (newCohortIndex: number) => {
    dispatch(dashboardActions.setCurrentCohortIndex(newCohortIndex));
  };

  return (
    <S.StyledDashboardContainer>
      {title && <Heading type={HeadingType.H2}>{title}</Heading>}
      {subtitle && <Paragraph margin="1.5rem 0 4rem">{subtitle}</Paragraph>}
      {cohorts.length > 0 ? (
        <>
          <Tabs
            color="redA"
            tabs={assessmentNames}
            active={currentCohortIndex}
            setActive={handleAssessmentTabChange}
          />
          {children}
        </>
      ) : (
        <EmptyDashboard />
      )}
    </S.StyledDashboardContainer>
  );
};
