/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { ReactElement, ForwardedRef, forwardRef, useState } from 'react';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';

export type Element = {
  id: number;
  name: string;
  title: string;
  challenges: Array<string>;
  questions: number;
};

interface ElementsSelectProps extends RegisterFormItemChild {
  items: Array<SelectItem>;
  cta: string;
  note?: string;
  onAddClick: (item: SelectItem) => void;
  selectedItems: Array<any>;
  icon?: string;
  parentCallback?: (argument: any) => void;
  noItemsText?: string;
  clearOnSelect?: boolean;
  disableAddButton?: boolean;
}

export const ElementsSelect = forwardRef(
  (
    {
      items,
      cta,
      note,
      selectedItems,
      name,
      formMethods,
      onAddClick,
      parentCallback,
      clearOnSelect,
      disableAddButton,
      ...props
    }: ElementsSelectProps,
    ref,
  ): ReactElement => {
    const [currentItem, setCurrentItem] = useState<SelectItem | undefined>(items[0]);
    const [reset, setReset] = useState<number>(0);

    const handleAddElement = () => {
      if (currentItem && parentCallback) {
        parentCallback(currentItem);
        onAddClick(currentItem);
      } else if (currentItem) {
        onAddClick(currentItem);
      }
      if (clearOnSelect) {
        setReset(reset + 1);
        setCurrentItem(undefined);
      }
    };

    const handleSelectItem = (item: SelectItem) => {
      setCurrentItem(item);
    };

    return (
      <>
        <Flex container gap="5rem">
          <Flex flexGrow="1">
            <Select
              {...props}
              ref={ref as ForwardedRef<HTMLDivElement>}
              items={items}
              variant="input"
              onSelect={handleSelectItem}
              reset={reset}
              placeholder="Select an option"
              isElementComponent
              name={name}
              formMethods={formMethods}
            />
          </Flex>
          <PrimaryButton
            type="button"
            onClick={handleAddElement}
            $size="regular"
            disabled={disableAddButton}
            $outline
          >
            {cta}
          </PrimaryButton>
        </Flex>
        {note && (
          <Text margin="1.5rem 0 0" type="label">
            {note}
          </Text>
        )}
      </>
    );
  },
);
