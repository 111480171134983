import styled from 'styled-components';
import { typeStyle } from 'styles/typeStyle';
import { FormItemContainer } from 'components/atoms/FormItemContainer/FormItemContainer';

export const StyledFormItemContainer = styled(FormItemContainer)`
  ${typeStyle.label};
  color: ${({ theme }) => theme.color.greyA};
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;

  input {
    margin-left: 16px;
  }
`;
