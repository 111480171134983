import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import * as S from './Intro.styles';
import introImage from 'assets/img/onboarding/intro-model.jpg';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { slideFade } from 'util/motionTransitions';
import { AnimationGroup } from 'components/util/AnimationGroup/AnimationGroup';

export const Intro = (): ReactElement => {
  return (
    <S.OnboardingIntro>
      <S.ContentWrapper>
        <S.ImageWrapper>
          <S.StyledImage src={introImage} alt="Intro image" fit="cover" />
        </S.ImageWrapper>
        <AnimationGroup>
          <S.TextWrapper>
            <motion.div animate>
              <S.StyledHeading type={HeadingType.H2}>
                <FormattedMessage
                  description="Onboarding - Intro - Title"
                  defaultMessage="What is the <bold>360º assessment?</bold>"
                  values={{
                    bold: (chunks: string) => <S.TitleHighlight>{chunks}</S.TitleHighlight>,
                  }}
                />
              </S.StyledHeading>
            </motion.div>
            <FormattedMessage
              description="Onboarding - Intro - Paragraph"
              defaultMessage="<p>The <bold>360º Assessment</bold> is a comprehensive leadership development tool. It has been developed by THNK to support leaders to reflect on their leadership and embark on a path of growth.</p><p>By using this tool you'll learn about your strengths and growth areas, providing you with actionable insights. You will be challenged to take action on those insights to enhance your unique set of gifts.</p>"
              values={{
                bold: (chunks: string) => <S.Bold>{chunks}</S.Bold>,
                p: (chunks: string) => (
                  <S.StyledParagraph variants={slideFade('y', 40, 1.5).variants}>
                    {chunks}
                  </S.StyledParagraph>
                ),
              }}
            />
          </S.TextWrapper>
        </AnimationGroup>
      </S.ContentWrapper>
      <S.PillShape>
        <S.PillCircle />
      </S.PillShape>
    </S.OnboardingIntro>
  );
};
