/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { RegisteredFormItem } from 'globalTypes';
import { MouseEvent, ReactElement, ChangeEvent, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { kebabCase } from 'lodash';
import * as S from './CreateCohortForm.styles';
import { MultiSelect } from 'components/atoms/MultiSelect/MultiSelect';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Input } from 'components/atoms/Input/Input';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Participant } from 'model/Participant';
import { Cohort } from 'model/Cohort';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { Survey } from 'model/Survey';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { Trash } from 'components/atoms/Icon/Icon';

interface CreateCohortFormProps extends RegisteredFormItem {
  isSubunit: boolean;
  cohortIndex: number;
  name?: string;
  cohort: Cohort;
  participants: Array<Participant>;
  surveys: Array<{ id: number; name: string }>;
  unitId?: number;
  subunitId?: number;
  isEditMode?: boolean;
  initialEditCohorts: Array<number>;
  handleCohortChange: (unitId: number, subunitId: number, cohortId: number, name: string) => void;
  handleDeleteTab: (cohortId: number) => void;
  handleSurveyChange: (unitId: number, subunitId: number, cohortId: number, survey: Survey) => void;
  handleParticipantChange: (
    unitId: number,
    subunitId: number,
    cohortId: number,
    newParticipantsList: Array<Participant>,
  ) => void;
}

export const CreateCohortForm = ({
  register,
  formMethods,
  errors,
  subunitId,
  isSubunit,
  name,
  cohortIndex,
  cohort,
  participants,
  surveys,
  unitId,
  handleParticipantChange,
  handleDeleteTab,
  handleSurveyChange,
  handleCohortChange,
  isEditMode,
  initialEditCohorts,
  ...props
}: CreateCohortFormProps): ReactElement => {
  const formName = kebabCase(name);
  const initialSurveyIndex =
    surveys.findIndex((survey) => survey.id === cohort.cohort_survey?.survey.id) || 0;
  const [selectedSurvey, setSelectedSurvey] = useState<number>(initialSurveyIndex);

  const formatedSurveys = useMemo(
    () => surveys.map((survey) => ({ title: survey.name, value: survey.id })),
    [surveys],
  );

  const deleteTab = (event: MouseEvent) => {
    if (cohort.id === undefined) throw new Error('Missing subunit id');
    event.preventDefault();
    handleDeleteTab(cohort.id);
  };

  // TODO: fix cohort name change
  const handleCohortNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const cohortName = event.target.value || '';

    if (unitId === undefined) throw new Error('Missing unit id');
    if (subunitId === undefined) throw new Error('Missing subunit id');
    if (cohort.id === undefined) throw new Error('Missing subunit id');

    handleCohortChange(unitId, subunitId, cohort.id, cohortName);
  };

  const handleSelectSurvey = (item: SelectItem) => {
    if (unitId === undefined) throw new Error('Missing unit id');
    if (subunitId === undefined) throw new Error('Missing subunit id');
    if (cohort.id === undefined) throw new Error('Missing subunit id');
    const newSurvey = Object.assign(new Survey(), { id: item.value, name: item.title });
    setSelectedSurvey(newSurvey.id);
    handleSurveyChange(unitId, subunitId, cohort.id, newSurvey);
  };

  // TODO: Decide if value should be an id
  const adaptParticipant = (participantsArray: Array<Participant>) => {
    return participantsArray.map((participant) => ({
      value: participant,
      label: participant.name,
    }));
  };

  const participantsAdapted = adaptParticipant(participants);

  const selectedParticipantsAdapted = adaptParticipant(cohort.participants);

  const handleParticipantInputChange = (options: Array<{ value: Participant; label: string }>) => {
    const participantsList = options.map((option) => option.value);

    if (unitId === undefined) throw new Error('Missing unit id');
    if (subunitId === undefined) throw new Error('Missing subunit id');
    if (cohort.id === undefined) throw new Error('Missing subunit id');

    handleParticipantChange(unitId, subunitId, cohort.id, participantsList);
  };

  return (
    <S.StyledCreateCohortForm container flexDirection="column" gap="4rem" {...props}>
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        registerErrors="required"
        name={`${formName}-cohort`}
        label="Cohort Name *"
        size={FormItemSizes.Large}
        alignError="left"
      >
        <Input
          handleInputChange={handleCohortNameChange}
          hasRef
          placeholder="Enter cohorts name"
          defaultValue={cohort.name}
        />
      </RegisterFormItem>
      <SecondaryButton
        color="blueA"
        onClick={(event: MouseEvent) => deleteTab(event)}
        className="delete-cohort"
      >
        <FormattedMessage
          description="Edit client - delete cohort"
          defaultMessage="Delete cohort"
        />
        <Trash />
      </SecondaryButton>

      <MultiSelect
        label="Participants"
        options={participantsAdapted}
        defaultValue={selectedParticipantsAdapted}
        onChange={handleParticipantInputChange}
      />

      <Select
        defaultValue={formatedSurveys[selectedSurvey]}
        items={formatedSurveys}
        label="Assessment *"
        placeholder="Select an assessment"
        variant="input"
        onSelect={handleSelectSurvey}
        disabled={isEditMode && initialEditCohorts.includes(cohort.id as number) && cohort !== null}
      />
    </S.StyledCreateCohortForm>
  );
};
