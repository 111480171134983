import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { size } from 'styles/helpers/size';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';
import { zIndex } from 'styles/zIndex';

export const StyledBarIconInner = styled.div<BarIconStyleProps>`
  ${size('4rem')}
  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.blueA};
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  cursor: pointer;

  svg {
    ${size('3.5rem')}
    color: ${({ color, theme }) => theme.color[color]};
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${size('4.5rem')}
  }
`;

export const StyledBarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 4.2rem;
  justify-content: space-between;
  gap: 2rem;
  z-index: ${zIndex('default')};
`;

export const StyledBar = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledBarBackground = styled.div`
  height: 1rem;
  border-radius: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.color.blueF};
  overflow: hidden;
`;

interface BarIconStyleProps {
  color: ColorKey;
}

export const StyledBarTotal = styled.div`
  color: ${({ theme }) => theme.color.blueC};
  font-size: 1.4rem;
  min-width: 1rem;
  text-align: right;
`;
