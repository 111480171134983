/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { Dispatch, ReactElement, SetStateAction, useState, useEffect, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { cloneDeep, differenceBy } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import { AdminHeading } from '../AdminHeading/AdminHeading';
import * as S from './ClientForm.styles';
import { CreateClientForm } from './sections/CreateClientForm/CreateClientForm';
import { CreateParticipantTable } from './sections/CreateParticipantTable/CreateParticipantTable';
import { OrganizationUnit as OrganizationUnitSection } from './sections/OrganizationUnit/OrganizationUnit';
import { AddUnit } from './sections/AddUnit/AddUnit';
import { Path } from 'routes/Path';
import { Form } from 'components/molecules/Form/Form';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { MediaQuery } from 'styles/mediaQuery';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { useAppDispatch } from 'redux/store';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Customer } from 'model/Customer';
import { Participant } from 'model/Participant';
import { OrganizationUnit } from 'model/OrganizationUnit';
import { OrganizationSubunit } from 'model/OrganizationSubunit';
import { Cohort } from 'model/Cohort';
import { Survey } from 'model/Survey';
import { Cohort as DashboardCohort } from 'services/types/DashboardTypes';
import { SurveyService } from 'services/SurveyService';
import { SubunitService } from 'services/SubunitService';
import { UnitService } from 'services/UnitService';
import { CohortService } from 'services/CohortService';
import { CustomerService } from 'services/CustomerService';
import { Loader } from 'components/atoms/Loading/Loading.styles';

interface ClientFormProps {
  handleCreateClient: (formData: FieldValues) => Promise<void>;
  setCurrentCustomer: Dispatch<SetStateAction<Customer>>;
  currentCustomer: Customer;
  participants: Array<Participant>;
  setParticipants: Dispatch<SetStateAction<Array<Participant>>>;
  isEditMode?: boolean;
}

export type UnitDataProps = {
  description: string;
  customer_id?: number;
  organizational_unit_id?: number;
};

export interface OrganizationUnitProps extends UnitDataProps {
  id: number;
}

export const ClientForm = ({
  handleCreateClient,
  currentCustomer,
  setCurrentCustomer,
  participants,
  setParticipants,
  isEditMode = false,
}: ClientFormProps): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory<LocationState>();
  const [disableCustomerButton, setDisableCustomerButton] = useState(false);
  const [surveys, setSurveys] = useState<Array<{ id: number; name: string }>>([]);
  const [editableCustomer, setEditableCustomer] = useState<Customer | null>(null);
  const [surveysAreLoading, setSurveysAreLoading] = useState(true);
  const [initialEditCohorts, setInitialEditCohorts] = useState<Array<number>>([]);

  // this is meant to be set to true if new data is needed from the server,
  // the less state we need to manage from FE the better. Try to use this instead of
  // trying to keep local state updated as it can lead to clients having data that is not in sync.
  // Setting this to true will hard update the client data, as it will retrigger the initial useEffect.
  const [refreshData, setRefreshData] = useState<boolean>(true);

  const customerLoaded = useMemo(
    () => (isEditMode ? typeof currentCustomer.id === 'number' : currentCustomer),
    [isEditMode, currentCustomer],
  );

  const handleClientFormSuccess = async (formData: FieldValues) => {
    setDisableCustomerButton(true);
    await handleCreateClient(formData);
    setDisableCustomerButton(false);
  };

  const withErrorHandling = (lambda: () => void) => {
    try {
      lambda();
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
    }
  };

  const handleAddCohort = (unitId?: number, subunitId?: number) => {
    if (currentCustomer.id === undefined) throw new Error('Missing customer id');
    if (unitId === undefined) throw new Error('Unit id was not found');
    if (subunitId === undefined) throw new Error('Subunit id was not found');

    const newCustomer = currentCustomer.clone();
    const unit = newCustomer.getUnitById(unitId);
    const subunit = unit.getSubunitById(subunitId);
    const newCohort = new Cohort();
    newCohort.name = `Cohort ${subunit.cohorts.length + 1}`;
    newCohort.id = Number(`${subunitId}${subunit.cohorts.length + 1}`);
    subunit.addCohort(newCohort);
    setCurrentCustomer(newCustomer);
  };

  const handleDeleteCohort = (unitId: number, subunitId: number, cohortId: number) => {
    if (cohortId === undefined) throw new Error('Missing cohort id');

    const newCustomer = currentCustomer.clone();
    const unit = newCustomer.getUnitById(unitId);
    const subunit = unit.getSubunitById(subunitId);
    subunit.removeCohortById(cohortId);
    setCurrentCustomer(newCustomer);
  };

  const handleDeleteUnit = (unitId?: number) => {
    withErrorHandling(async () => {
      if (unitId === undefined) throw new Error('Missing unit id');

      await UnitService.removeUnit(unitId);
      const newCustomer = currentCustomer.clone();
      newCustomer.removeUnitById(unitId);
      setCurrentCustomer(newCustomer);
    });
  };

  const handleAddSubunit = (subunitDescription: string, unitId?: number) => {
    withErrorHandling(async () => {
      if (currentCustomer.id === undefined) throw new Error('Missing customer id');
      if (unitId === undefined) throw new Error('Unit id was not found');

      const newCustomer = currentCustomer.clone();
      const unit = newCustomer.getUnitById(unitId);
      const newSubunit = new OrganizationSubunit();
      newSubunit.description = subunitDescription;

      const subunitCreated = await SubunitService.createSubunit(unitId, newSubunit);
      newSubunit.id = subunitCreated.id;
      unit.organizational_sub_units.push(newSubunit);
      setCurrentCustomer(newCustomer);
      handleAddCohort(unit.id, newSubunit.id);
    });
  };

  const handleAddUnit = async (unitDescription: string) => {
    if (currentCustomer.id === undefined) throw new Error('Missing customer id');

    const unit = new OrganizationUnit();
    unit.description = unitDescription;
    try {
      const { id } = await UnitService.createUnit(currentCustomer.id, unit);
      unit.id = id;
      const newCustomer = currentCustomer.clone();
      newCustomer.addUnit(unit);
      setCurrentCustomer(newCustomer);
      // TODO: Keeping this here as a workaround bc we suspect subunits will be required after MVP
      handleAddSubunit('Default', id);
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
    }
  };

  const handleDeleteSubunit = (unitId: number, subunitId: number) => {
    withErrorHandling(async () => {
      if (unitId === undefined) throw new Error('Missing unit id');
      if (subunitId === undefined) throw new Error('Missing subunit id');

      await SubunitService.removeSubunit(subunitId);
      const newCustomer = currentCustomer.clone();
      const unit = newCustomer.getUnitById(unitId);
      unit.removeSubunitById(subunitId);
      setCurrentCustomer(newCustomer);
    });
  };

  const handleCohortChange = (
    unitId: number,
    subunitId: number,
    cohortId: number,
    name: string,
  ) => {
    const newCustomer = currentCustomer.clone();
    const unit = newCustomer.getUnitById(unitId);
    const subunit = unit.organizational_sub_units.find((subunit) => subunit.id === subunitId);
    const cohort = subunit?.cohorts.find((cohort) => cohort.id === cohortId);
    if (cohort) {
      cohort.name = name;
    }
    setCurrentCustomer(newCustomer);
  };

  const handleParticipantChange = (
    unitId: number,
    subunitId: number,
    cohortId: number,
    newParticipantsList: Array<Participant>,
  ) => {
    const newCustomer = currentCustomer.clone();
    const unit = newCustomer.getUnitById(unitId);
    const subunit = unit.organizational_sub_units.find((subunit) => subunit.id === subunitId);
    const cohort = subunit?.cohorts.find((cohort) => cohort.id === cohortId);

    if (cohort) {
      cohort.participants = newParticipantsList;
    }
    setCurrentCustomer(newCustomer);
  };

  const handleSurveyChange = (
    unitId: number,
    subunitId: number,
    cohortId: number,
    survey: Survey,
  ) => {
    const newCustomer = currentCustomer.clone();
    const unit = newCustomer.getUnitById(unitId);
    const subunit = unit.organizational_sub_units.find((subunit) => subunit.id === subunitId);
    const cohort = subunit?.cohorts.find((cohort) => cohort.id === cohortId);

    if (cohort && cohort.cohort_survey) {
      cohort.cohort_survey.survey = survey;
    }
    setCurrentCustomer(newCustomer);
  };

  const handleFormSubmition = async () => {
    try {
      const customerId = currentCustomer.id;
      if (customerId === undefined) throw new Error('Missing customer id');
      currentCustomer.organizationUnits.forEach(async (unit) => {
        unit.organizational_sub_units.forEach(async (subunit) => {
          subunit.cohorts.forEach(async (cohort) => {
            if (subunit.id === undefined) throw new Error('Missing subunit id');
            const newCohort = await CohortService.createCohort(
              unit.id,
              subunit.id,
              customerId,
              cohort,
            );
            newCohort.participants.forEach(async (participant) => {
              if (newCohort.id === undefined) throw new Error('Missing cohort id');
              if (participant.id === undefined) throw new Error('Missing participant id');
              await CohortService.addParticipantToCohort(participant.id, newCohort.id);
            });
          });
        });
      });

      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
      history.push(Path.ClientOverview);
    } catch (error) {
      // TODO: Delete entities from DB to keep consistency
      dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
    }
  };

  const handleFormEditSubmition = async () => {
    // TODO: Refactor after UI/UX changes con cohort microservices
    try {
      const customerId = currentCustomer.id;
      if (customerId === undefined) throw new Error('Missing customer id');

      currentCustomer.organizationUnits.forEach((unit, unitIndex) => {
        unit.organizational_sub_units.forEach((subunit, subunitIndex) => {
          // Cohorts to delete flow
          const editableCohorts =
            editableCustomer?.organizationUnits[unitIndex]?.organizational_sub_units[subunitIndex]
              ?.cohorts;
          if (editableCohorts) {
            const cohortsToDelete = differenceBy(editableCohorts, subunit.cohorts, 'id');
            cohortsToDelete.forEach(async (cohortToDelete) => {
              if (!cohortToDelete.id) throw new Error('Missing cohort id');
              await CohortService.deleteCohort(cohortToDelete.id);
            });
          }

          // Cohorts to add flow
          const cohortsToAdd = differenceBy(
            subunit.cohorts,
            editableCustomer?.organizationUnits[unitIndex]?.organizational_sub_units[subunitIndex]
              ?.cohorts as Array<Cohort>,
            'id',
          );
          if (cohortsToAdd) {
            cohortsToAdd.forEach(async (cohortToAdd) => {
              if (!cohortToAdd.id) throw new Error('Missing cohort id');
              const newCohort = await CohortService.createCohort(
                unit.id,
                subunit.id,
                customerId,
                cohortToAdd,
              );

              newCohort.participants.forEach(async (participant) => {
                if (newCohort.id === undefined) throw new Error('Missing cohort id');
                if (participant.id === undefined) throw new Error('Missing participant id');
                await CohortService.addParticipantToCohort(participant.id, newCohort.id);
              });
            });
          }

          if (!editableCohorts && !cohortsToAdd) throw new Error('Missing Cohorts');
          if (!editableCohorts) return;

          // Cohorts to edit flow
          const sameCohorts = subunit.cohorts.filter(
            (cohort) => !cohortsToAdd.some((cohortToAdd) => cohort.id === cohortToAdd.id),
          );

          sameCohorts.forEach(async (cohort, cohortIndex) => {
            if (!cohort) throw new Error('Missing cohort');
            if (!editableCohorts) throw new Error('Missing cohorts');
            await CohortService.editCohort(unit.id, subunit.id, customerId, cohort);

            const editableCohortParticipants = editableCohorts[cohortIndex].participants;
            const participantsToDelete = differenceBy(
              editableCohortParticipants,
              cohort.participants,
              'id',
            );
            participantsToDelete.forEach(async (participantToDelete) => {
              if (!participantToDelete.cohort_participant_id)
                throw new Error('Missing cohort_participant_id');
              await CohortService.removeParticipantFromCohort(
                participantToDelete.cohort_participant_id,
              );
            });
            const participantsToAdd = differenceBy(
              cohort.participants,
              editableCohortParticipants,
              'id',
            );
            participantsToAdd.forEach(async (participantToAdd) => {
              if (!participantToAdd.id) throw new Error('Missing participant id');
              if (!cohort.id) throw new Error('Missing participant id');

              await CohortService.addParticipantToCohort(participantToAdd.id, cohort.id);
            });
          });
        });
      });
      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
      history.push(Path.ClientOverview);
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
    }
  };

  const formIsReady = () => {
    let cohortsAreReady = true;

    currentCustomer.organizationUnits.forEach((unit) => {
      unit.organizational_sub_units.forEach((subunit) => {
        subunit.cohorts.forEach((cohort) => {
          if (!cohort.name) cohortsAreReady = false;
          if (!cohort.cohort_survey) cohortsAreReady = false;
          if (!cohort.cohort_survey?.survey) cohortsAreReady = false;
          if (!cohort.cohort_survey?.survey.id) cohortsAreReady = false;
          if (!cohort.cohort_survey?.survey.name) cohortsAreReady = false;
        });
      });
    });

    return (
      // false &&
      cohortsAreReady &&
      currentCustomer &&
      participants &&
      participants.length > 0 &&
      currentCustomer.organizationUnits.length > 0 &&
      currentCustomer.organizationUnits.every((unit) => unit.organizational_sub_units.length > 0)
    );
  };

  useEffect(() => {
    if (!refreshData) return;
    if (isEditMode) {
      const getCustomer = async () => {
        const customer = await CustomerService.getCustomer(history.location.state.id.toString());
        const newOrganizationalUnits: Array<OrganizationUnit> = customer.organizational_units.map(
          (organizationalUnit: OrganizationUnit) =>
            Object.assign(new OrganizationUnit(), {
              ...organizationalUnit,
              organizational_sub_units: organizationalUnit.organizational_sub_units
                ? organizationalUnit.organizational_sub_units.map((subunit) =>
                    Object.assign(new OrganizationSubunit(), {
                      ...subunit,
                      cohorts: subunit.cohorts.map((cohort) =>
                        Cohort.fromDashboardCohort(cohort as unknown as DashboardCohort),
                      ),
                    }),
                  )
                : [],
            }),
        );

        const newCustomer: Customer = Object.assign(new Customer(), {
          ...customer,
          id: customer.id,
          function_id: customer.function.id,
          industry_id: customer.industry.id,
          organizational_type_id: customer.organizational_type.id,
          organizationUnits: newOrganizationalUnits,
        });
        setParticipants(newCustomer.participants);
        setEditableCustomer(cloneDeep(newCustomer));
        setCurrentCustomer(newCustomer);
        setInitialEditCohorts(
          newCustomer.organizational_units.flatMap((unit) =>
            unit.organizational_sub_units.flatMap((subunit) =>
              subunit.cohorts.map((cohort) => cohort.id || NaN),
            ),
          ),
        );
      };
      getCustomer();
    }

    const fetchSurveys = async () => {
      const surveys = await SurveyService.getSurveys();
      const formatedSurveys = surveys.map((survey) => ({
        id: survey.id,
        name: survey.name,
      }));

      setSurveys(formatedSurveys);
      setSurveysAreLoading(false);
    };

    fetchSurveys();
    setRefreshData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  if (!customerLoaded || surveysAreLoading) return <Loader />;

  return (
    <S.StyledClientForm>
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />
      <AdminHeading
        $underline
        $color="blueA"
        title={isEditMode ? `Edit Client - ${currentCustomer?.name}` : 'Add a new client'}
        breadcrumbs={[
          { title: 'Client Overview', href: Path.ClientOverview },
          { title: isEditMode ? 'Edit Client' : 'Add a new client' },
        ]}
      />
      {customerLoaded && (
        <CreateClientForm
          handleClientFormSuccess={handleClientFormSuccess}
          currentCustomer={currentCustomer}
          isEditMode={isEditMode}
          disabled={disableCustomerButton}
        />
      )}
      {(currentCustomer.id !== undefined || isEditMode) && (
        <>
          <Form>
            <CreateParticipantTable
              customer={currentCustomer}
              participants={participants}
              onParticipantDeleted={() => {
                setRefreshData(true);
              }}
              setParticipants={setParticipants}
              isEditMode={isEditMode}
              register
            />

            {participants.length > 0 && (
              <>
                <Flex container gap="15rem" alignItems="center" justifyContent="space-between">
                  <AddUnit
                    defaultValue="Unit"
                    name="currentUnitDescription"
                    handleAddUnit={handleAddUnit}
                    register
                  />
                </Flex>
                {!surveysAreLoading &&
                  currentCustomer.organizationUnits.length > 0 &&
                  currentCustomer.organizationUnits.map((unit, index) => {
                    return (
                      <OrganizationUnitSection
                        isEditMode={isEditMode}
                        surveys={surveys}
                        participants={participants}
                        unit={unit}
                        key={`org-${unit.description}`}
                        initialEditCohorts={initialEditCohorts}
                        handleAddSubunit={handleAddSubunit}
                        handleDeleteSubunit={handleDeleteSubunit}
                        handleDeleteUnit={handleDeleteUnit}
                        handleAddCohort={handleAddCohort}
                        handleDeleteCohort={handleDeleteCohort}
                        handleCohortChange={handleCohortChange}
                        handleParticipantChange={handleParticipantChange}
                        handleSurveyChange={handleSurveyChange}
                        unitIndex={index}
                        register
                      />
                    );
                  })}
              </>
            )}
          </Form>
          <PrimaryButton
            $size="regular"
            type="submit"
            disabled={!formIsReady()}
            onClick={isEditMode ? handleFormEditSubmition : handleFormSubmition}
          >
            Finish
          </PrimaryButton>
        </>
      )}
    </S.StyledClientForm>
  );
};
