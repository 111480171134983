import React, { ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import type { FieldValues } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form } from '../../../../molecules/Form/Form';
import { messages } from '../../Login.locale';
import { LoginFormType } from '../../Login.types';
import * as S from './ResetForm.styles';
import { StyledPrimaryButton, StyledRegisterFormItem } from '../LoginForm/LoginForm.styles';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { slideFade } from 'util/motionTransitions';
import { useDeviceState } from 'hooks/useDeviceState';
import { Input } from 'components/atoms/Input/Input';
import { ApiClient } from 'util/apiClient';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { Path } from 'routes/Path';

interface ResetFormProps {
  setActiveForm: React.Dispatch<React.SetStateAction<LoginFormType>>;
}

export const ResetForm = ({ setActiveForm }: ResetFormProps): ReactElement => {
  const [isSuccess, setIsSucces] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [isResetDisabled, setIsResetDisabled] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(100);
  const { isMobile } = useDeviceState();
  const intl = useIntl();
  const history = useHistory();

  const onFormSuccess = async (formData: FieldValues) => {
    // eslint-disable-next-line no-console
    ApiClient.post('forgot-password', { email: formData.email })
      .then(() => {
        dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
        history.push(Path.Login);
      })
      .catch(() => {
        dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
      });
    setIsSucces(true);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (countdown === 0) {
      setIsResetDisabled(false);
    }
    if (isSuccess && countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (!isSuccess) {
      setCountdown(60);
      setIsResetDisabled(true);
    }

    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, [isSuccess, countdown]);

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        layout
        {...slideFade()}
        key={isSuccess ? 'message' : 'form'}
        style={{ width: '100%' }}
      >
        {isSuccess ? (
          <>
            {intl.formatMessage(messages.resetPasswordConfirmation, {
              seconds: countdown,
              p: (chunk) => <S.Message>{chunk}</S.Message>,
            })}
            <S.ButtonWrapper>
              <StyledPrimaryButton
                type="button"
                $size={isMobile ? 'regular' : 'large'}
                onClick={() => setActiveForm(LoginFormType.LoginForm)}
                $backgroundColor="redA"
              >
                {intl.formatMessage(messages.backToLoginButton)}
              </StyledPrimaryButton>
              <SecondaryButton
                disabled={isResetDisabled}
                color="blueA"
                type="button"
                onClick={() => setIsSucces(false)}
              >
                <span>{intl.formatMessage(messages.reSendLinkButton)}</span>
              </SecondaryButton>
            </S.ButtonWrapper>
          </>
        ) : (
          <Form onSuccess={onFormSuccess}>
            <StyledRegisterFormItem
              register
              registerErrors="required"
              name="email"
              label={intl.formatMessage(messages.emailLabel)}
              size="Medium"
            >
              <Input hasRef placeholder={intl.formatMessage(messages.enterEmail)} type="email" />
            </StyledRegisterFormItem>
            <S.ButtonWrapper>
              <StyledPrimaryButton
                type="submit"
                $size={isMobile ? 'regular' : 'large'}
                $backgroundColor="redA"
              >
                {intl.formatMessage(messages.sendLinkButton)}
              </StyledPrimaryButton>
              <SecondaryButton
                type="button"
                color="blueA"
                onClick={() => setActiveForm(LoginFormType.LoginForm)}
              >
                <span>{intl.formatMessage(messages.cancelButton)}</span>
              </SecondaryButton>
            </S.ButtonWrapper>
          </Form>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
