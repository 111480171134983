import styled from 'styled-components';
import { motion } from 'framer-motion';
import { respondTo } from '../../../styles/helpers/respondTo';
import { size } from 'styles/helpers/size';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { HeadingType } from 'components/atoms/Heading/Heading.data';

export const LoginPage = styled.section`
  min-height: 100vh;
  padding: 5rem ${({ theme }) => theme.sitePaddings.mobile};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: ${({ theme }) => theme.color.blueE};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 10rem ${({ theme }) => theme.sitePaddings.desktop};
  }
`;

export const ContentWrapper = styled(motion.div)`
  max-width: 103rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  transition: height 0.5s ease;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    min-width: 76.8rem;
    flex-direction: row;
    align-items: center;
  }
`;

export const HeadingWrapper = styled(motion.div)`
  ${size('100%')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  margin-bottom: 2.8rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    height: auto;
    margin: 0;
  }
`;

export const Heading = styled(motion.h2)`
  ${typeStyle[HeadingType.H2]};
`;

export const FormWrapper = styled(motion.div)`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  padding: 80px;
`;
