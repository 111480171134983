import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';

export const StyledCardContainer = styled(CardContainer)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    height: 600px;
  }
`;
