import styled, { css } from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { size } from 'styles/helpers/size';
import { StyledPaginationButton } from 'components/molecules/PaginationButtons/PaginationButtons.styles';

export const ClientCardsList = styled(Flex)``;

export const CardPaginationButton = styled(StyledPaginationButton)`
  ${size('3.2rem')};
  flex-shrink: 0;
  color: ${({ theme }) => theme.color.blueA};
  margin: 0 2.5rem;

  svg {
    width: 0.5rem;
  }
`;

export const RightCardPaginationButton = styled(CardPaginationButton)`
  &:last-of-type {
    svg {
      transform: scaleX(-1);
    }
  }
`;

export const CardsSlider = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const SliderIndicatorItem = styled.ul<{ $isActive?: boolean }>`
  ${size('0.7rem')}
  background-color: ${({ theme }) => theme.color.greyA};
  border-radius: 50%;
  border: solid 1px transparent;
  transform: scale(0.4);
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: transparent;
      border-color: ${({ theme }) => theme.color.blueA};
      transform: scale(1);
    `}
`;
