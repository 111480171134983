import { ChangeEventHandler, MouseEventHandler, ReactElement } from 'react';
import * as S from './Radio.styles';
import { Text } from '../Text/Text';
import { MediaQuery } from 'styles/mediaQuery';

export interface RadioProps extends S.RadioStyleProps {
  label: string;
  name?: string;
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const Radio = ({
  name,
  label,
  id,
  checked,
  defaultChecked,
  mobileType,
  onChange,
  onClick,
  ...props
}: RadioProps): ReactElement => {
  const isChecked = checked || defaultChecked;

  return (
    <S.StyledRadioContainer mobileType={mobileType} {...props}>
      <S.StyledRadio
        mobileType={mobileType}
        type="radio"
        name={name}
        id={id || label}
        checked={checked}
        defaultChecked={defaultChecked}
        onClick={onClick}
        onChange={onChange}
      />
      <Text
        as="label"
        htmlFor={id || label}
        type="labelB"
        margin={[
          mobileType === 'box' ? '1.6rem 0 0' : '0 0 0 2rem',
          [MediaQuery.MIN_768, '1rem 0 0'],
        ]}
        color={isChecked ? 'black' : 'greyA'}
      >
        {label}
      </Text>
    </S.StyledRadioContainer>
  );
};
