import { ReactElement, useMemo } from 'react';
import { mean as average, isNaN } from 'lodash';
import * as S from './NumericScore.styles';
import { ScoreBar } from 'components/molecules/ScoreBar/ScoreBar';
import { Question } from 'services/types/DashboardTypes';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';

interface NumericScoreProps {
  question: Question;
  selfLabel: string;
  assessorsLabel: string;
}

export const NumericScore = ({
  assessorsLabel,
  selfLabel,
  question,
}: NumericScoreProps): ReactElement => {
  const assessorsAverage = useMemo(
    () => Math.round(average(question.assessor_answers.filter((answer) => answer !== -1))),
    [question],
  );

  return (
    <S.StyledScoreBarFlex>
      <div>
        <UppercaseText>{selfLabel}</UppercaseText>
        {typeof question.self_answer === 'number' ? (
          <ScoreBar score={question.self_answer} />
        ) : (
          <S.StyledParagraph color="cream">
            The participant hasn’t completed the assessment. You can always send them a friendly
            reminder.
          </S.StyledParagraph>
        )}
      </div>

      <div>
        <UppercaseText>{assessorsLabel}</UppercaseText>
        {typeof assessorsAverage === 'number' && !isNaN(assessorsAverage) ? (
          <ScoreBar score={assessorsAverage} />
        ) : (
          <S.StyledParagraph color="cream">
            There are still some assessors that haven’t completed the assessment. You can always
            send them a friendly reminder.
          </S.StyledParagraph>
        )}
      </div>
    </S.StyledScoreBarFlex>
  );
};
