import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import * as S from './Hero.styles';
import heroImage from 'assets/img/onboarding/hero-model.jpg';
import { useDeviceState } from 'hooks/useDeviceState';
import { Transitions } from 'util/motionTransitions';
import { AnimationGroup } from 'components/util/AnimationGroup/AnimationGroup';

export const Hero = (): ReactElement => {
  const { isMobile } = useDeviceState();

  const Ellipses = (
    <S.EllipseGroup>
      <S.Ellipse />
      <S.Ellipse />
      <S.Ellipse />
      <S.Ellipse />
      <S.Ellipse />
    </S.EllipseGroup>
  );

  return (
    <S.OnboardingHero>
      <AnimationGroup>
        <S.StyledHeroWrapper>
          <S.ContentWrapper>
            <S.StyledEyebrow animate>
              <FormattedMessage
                description="Onboarding - Hero - Eyebrow"
                defaultMessage="Welcome to the <bold>THNK 360º Assessment</bold>"
                values={{
                  bold: (chunks: string) => <S.Bold>{chunks}</S.Bold>,
                }}
              />
            </S.StyledEyebrow>

            <S.HeadlineWrapper>
              <S.Headline>
                <S.HeadlineSpan animate>
                  <FormattedMessage
                    description="Onboarding - Hero - Headline 1"
                    defaultMessage="Develop your"
                  />
                  {isMobile && (
                    <>
                      <S.PillGraphic>
                        <S.PillCircle />
                        <S.Ellipse />
                      </S.PillGraphic>
                    </>
                  )}
                </S.HeadlineSpan>
                <S.HeadlineSpan animate>
                  <FormattedMessage
                    description="Onboarding - Hero - Headline 2"
                    defaultMessage="leadership"
                  />
                  {!isMobile && (
                    <S.PillGraphic>
                      <S.PillCircle />
                    </S.PillGraphic>
                  )}
                </S.HeadlineSpan>
              </S.Headline>
              <S.ParagraphWrapper animate={Transitions.Fade}>
                <S.StyledParagraph>
                  <FormattedMessage
                    description="Onboarding - Hero - Paragraph"
                    defaultMessage="Level up your leadership by reflecting, soliciting feedback and taking action."
                  />
                </S.StyledParagraph>
                {isMobile && <S.StyledArrow />}
              </S.ParagraphWrapper>
            </S.HeadlineWrapper>

            {!isMobile && (
              <S.ArrowWrapper animate>
                <S.StyledArrow />
                {Ellipses}
              </S.ArrowWrapper>
            )}
          </S.ContentWrapper>

          <S.ImageWrapper animate>
            <S.ImageContent>
              {!isMobile && <S.CircleOutlines />}
              <S.StyledImage src={heroImage} alt="Hero image" fit="cover" />
            </S.ImageContent>
            {isMobile && (
              <>
                {Ellipses}
                {Ellipses}
              </>
            )}
          </S.ImageWrapper>
        </S.StyledHeroWrapper>
      </AnimationGroup>
    </S.OnboardingHero>
  );
};
