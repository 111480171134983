/* eslint-disable import/no-unresolved */
import type { ReactElement } from 'react';
import { RegisteredFormItem } from 'globalTypes';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Select, SelectItem } from 'components/molecules/Select/Select';

interface ClientSelectProps extends RegisteredFormItem {
  items: Array<SelectItem>;
  copy: string;
  name: string;
  onSelect?: (item: SelectItem) => void;
}

export const ClientSelect = ({
  formMethods,
  register,
  errors,
  items,
  copy,
  name,
  onSelect,
  defaultValue,
}: ClientSelectProps): ReactElement => {
  return (
    <RegisterFormItem
      errors={errors}
      register={register}
      formMethods={formMethods}
      registerErrors="required"
      name={name}
      size={FormItemSizes.Medium}
      alignError="left"
      label={`Client ${copy}`}
    >
      <Select
        hasRef
        items={items}
        variant="input"
        onSelect={onSelect}
        defaultValue={defaultValue}
        placeholder="Please select an option"
      />
    </RegisterFormItem>
  );
};
