/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useState } from 'react';
import { QuestionSliderPreview } from 'components/atoms/QuestionSliderPreview/QuestionSliderPreview';
import {
  FormItemSizes,
  StyledLabel,
} from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Text } from 'components/atoms/Text/Text';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { AdminHeading } from 'components/pages/Superadmin/components/AdminHeading/AdminHeading';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { assessmentData } from 'data/assessmentData';

export const QuestionLevel = ({
  register,
  formMethods,
  errors,
}: RegisteredFormItem): ReactElement => {
  const [questionLevel, setQuestionLevel] = useState(0);

  // Due to 1-5, 1-7, 1-10
  const QuestionlevelAdapter = [5, 7, 10];

  const handleQuestionLevelSelect = ({ value }: SelectItem) => {
    setQuestionLevel(value as number);
    formMethods.setValue('question_level', value);
  };

  return (
    <Flex container flexDirection="column" gap="1.5rem">
      <AdminHeading title="Set Question Characteristics" $size="small" $underline />
      <Flex container gap="2rem">
        <Flex gap="2rem" flexBasis="50%">
          <RegisterFormItem
            register={register}
            formMethods={formMethods}
            errors={errors}
            registerErrors="required"
            name="question_level"
            label="Question Level"
            size={FormItemSizes.Medium}
            alignError="right"
          >
            <Select
              onSelect={handleQuestionLevelSelect}
              items={assessmentData.scores}
              defaultValue={assessmentData.scores[0].value}
              variant="input"
              placeholder="Select an option"
            />
          </RegisterFormItem>
        </Flex>
        <Flex gap="2rem" flexGrow="1">
          <StyledLabel size={FormItemSizes.Large}>Final Question Slider</StyledLabel>
          <QuestionSliderPreview maxScore={QuestionlevelAdapter[questionLevel]} />
        </Flex>
      </Flex>
      <Text type="label">Set a number between 5 and 10</Text>
    </Flex>
  );
};
