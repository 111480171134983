/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { memo, ReactElement, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionLevel } from './sections/QuestionLevel/QuestionLevel';
import { ElementFormItem } from './sections/ElementFormItem/ElementFormItem';
import * as S from './CreateAssessment.styles';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { MediaQuery } from 'styles/mediaQuery';
import {
  RegisterErrorProp,
  RegisterFormItem,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Input } from 'components/atoms/Input/Input';
import { FormSticky } from 'components/molecules/FormSticky/FormSticky';
import { Path } from 'routes/Path';
import { RootState } from 'redux/store';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { surveysActions } from 'redux/ducks/surveysDuck/surveysDuckReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { SurveyDataProps } from 'redux/ducks/surveysDuck/surveysDuckTypes';
import { SurveyService } from 'services/SurveyService';
import { clearSelectedChallenges } from 'redux/ducks/selectedChallengesOrQuestionsDuck/selectedChallengesOrQuestionsActions';

export const CreateAssessment = memo((): ReactElement => {
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const { push: redirectTo } = useHistory();

  const dispatch = useDispatch();

  const { selectedChallenges, selectedQuestions } = useSelector((state: RootState) => {
    return state.selectedChallengesOrQuestions;
  });

  const { elements } = useSelector((state: RootState) => {
    return state.elements;
  });

  const registerErrors: Record<string, RegisterErrorProp> = {
    assessmentName: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 50,
      },
    ],
  };

  const validateElements = (elements: Array<any>) => {
    elements.forEach((element) => {
      if (element.questions.length === 0) {
        console.log(element);
        console.log(element.questions);
        console.log(element.questions.length);
        throw new Error(Events.FORM_MANDATORY_QUESTIONS_ERROR);
      }
    });
  };

  const fetchElementsArray = () => {
    const elementsArray = selectedChallenges.map((item: any) => {
      const selectedQuestion = selectedQuestions.find(
        (question) => item.element_name === question.element_name,
      );
      const questions = selectedQuestion?.questions || [];
      const element_id: Array<any> = elements.filter(
        (element: any) => item.element_name === element.name,
      );

      return {
        ...item,
        order: 0,
        element_id: element_id[0].id,
        questions,
      };
    });
    return elementsArray;
  };

  const handleValidate = () => {
    const elementsArray = fetchElementsArray();
    try {
      validateElements(elementsArray);
      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
    } catch (error) {
      if (error instanceof Error && Object.values(Events).includes(error.message as Events)) {
        dispatch(setErrorEventAction(error.message as Events));
      } else {
        dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
      }
    }
  };

  const handleSuccess = async (values: FieldValues) => {
    try {
      const { assessment_name: name, question_level } = values;
      const elementsArray = fetchElementsArray();
      validateElements(elementsArray);

      const survey = {
        version_id: 1,
        name,
        question_level,
        parent_id: null,
      };

      const surveyFinalBody: SurveyDataProps = {
        survey,
        elements: elementsArray,
      };

      await SurveyService.createSurvey(surveyFinalBody);
      dispatch(setSuccessEventAction(Events.FORM_ASSESSMENT_SAVED));
      redirectTo(Path.AssessmentManagement);
    } catch (error) {
      if (error instanceof Error && Object.values(Events).includes(error.message as Events)) {
        dispatch(setErrorEventAction(error.message as Events));
      } else {
        dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
      }
    }
  };

  const handleCancel = () => {
    redirectTo(Path.AssessmentManagement);
  };

  useEffect(() => {
    dispatch(surveysActions.surveysApiHandler({ method: ActionMethod.GET }));
    dispatch(clearSelectedChallenges());
  }, [dispatch]);

  return (
    <S.StyledCreateAssessment>
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />

      <AdminHeading
        title="Create a new assessment"
        $color="blueA"
        $underline
        breadcrumbs={[
          { title: 'Assessment Management', href: '/admin/assessment-management' },
          { title: 'create assessment' },
        ]}
      />

      <S.StyledForm onSuccess={handleSuccess} customMethods={formMethods}>
        <Flex container gap="2rem">
          <Flex flexBasis="50%">
            <RegisterFormItem
              register
              registerErrors={registerErrors.assessmentName}
              name="assessment_name"
              label="Assessment Name"
              size={FormItemSizes.Medium}
              alignError="left"
            >
              <Input hasRef placeholder="Enter clients name" />
            </RegisterFormItem>
          </Flex>
        </Flex>
        <QuestionLevel register />

        <ElementFormItem register />

        <FormSticky
          sidePadding={undefined}
          confirmCopy="save & finish"
          handleCancel={handleCancel}
          handleValidate={handleValidate}
          validateButton
        />
      </S.StyledForm>
    </S.StyledCreateAssessment>
  );
});
