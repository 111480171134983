export const SET_ERROR_EVENT = 'SET_ERROR_EVENT';
export const SET_SUCCESS_EVENT = 'SET_SUCCESS_EVENT';

export enum Events {
  FORM_INVALID_ERROR = 'FORM_INVALID_ERROR',
  PICTURE_INVALID_EXTENSION = 'PICTURE_INVALID_EXTENSION',
  FORM_SUCCESS = 'FORM_SUCCESS',
  FORM_REQUEST_ERROR = 'FORM_REQUEST_ERROR',
  FORM_MANDATORY_QUESTIONS_ERROR = 'FORM_MANDATORY_QUESTIONS_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  FORM_DELETE_SUCCESS = 'FORM_DELETE_SUCCESS',
  FORM_MISSION_SAVED = 'FORM_MISSION_SAVED',
  FORM_ASSESSMENT_SAVED = 'FORM_ASSESSMENT_SAVED',
  COPY_CLIPBOARD_SUCCESS = 'COPY_CLIPBOARD_SUCCESS',
  COHORT_USER_DELETED = 'COHORT_USER_DELETED',
  ADD_MISSION_COMPLETED_SUCCESS = 'ADD_MISSION_COMPLETED_SUCCESS',
  MAXIMUM_NUMBER_OF_ELEMENTS_EXCEEDED = 'MAXIMUM_NUMBER_OF_ELEMENTS_EXCEEDED',
  MAXIMUM_NUMBER_OF_QUESTIONS_EXCEEDED = 'MAXIMUM_NUMBER_OF_QUESTIONS_EXCEEDED',
  MAXIMUM_NUMBER_OF_CHALLENGES_EXCEEDED = 'MAXIMUM_NUMBER_OF_CHALLENGES_EXCEEDED',
}
