import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fluidType } from 'styles/helpers/fluidType';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { LargeArrow } from 'components/atoms/Icon/Icon';
import { Image } from 'components/atoms/Image/Image';
import { bounce } from 'styles/keyframes';
import { size } from 'styles/helpers/size';

export const OnboardingHero = styled(motion.section)`
  padding: 10rem ${({ theme }) => theme.sitePaddings.mobile} 0;
  position: relative;
  overflow: hidden;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 18.5rem ${({ theme }) => theme.sitePaddings.desktop} 0;
  }
`;

export const ContentWrapper = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
`;

export const StyledHeroWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  min-height: 83vh;
`;

export const ImageWrapper = styled(motion.div)`
  display: flex;
  grid-column: 8/-1;
  grid-row: 1;
`;

export const ImageContent = styled.div`
  position: relative;
`;

export const CircleOutlines = styled.div`
  width: 32%;
  padding-top: 32%;
  border-radius: 100%;
  border: solid 2px ${({ theme }) => theme.color.blueA};
  position: absolute;
  top: 0;
  right: 0;

  &:after {
    ${size('58%')};
    content: '';
    position: absolute;
    border-radius: 100%;
    border: solid 2px ${({ theme }) => theme.color.blueA};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledImage = styled(Image)`
  ${size('100%')}
  position: relative;
  border-radius: 500px 500px 0 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.cream};
`;

export const StyledEyebrow = styled(motion.h3)`
  ${typeStyle.h3};
`;

export const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.color.blueA};
`;

export const Ellipse = styled.span`
  border-radius: 100%;
  ${size('2.5em')}
  background-color: ${({ theme }) => theme.color.redA};

  &:nth-child(3) {
    background-color: ${({ theme }) => theme.color.blueA};
  }
`;

export const HeadlineWrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  z-index: 1;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 5.8rem;
  }

  @media ${respondTo(MediaQuery.MIN_1440)} {
  }
`;

export const HeadlineSpan = styled(motion.span)`
  display: block;
  position: relative;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    &:first-child {
      padding-left: 0.8em;
    }
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    &:first-child {
      padding-left: 9.5rem;
    }
  }

  ${Ellipse} {
    position: absolute;
    background-color: ${({ theme }) => theme.color.blueA};
    right: -7.5rem;
    bottom: 0;
    ${size('0.6em')}
  }
`;

export const Headline = styled.h2`
  ${typeStyle.h2};
  font-size: 6.5rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 9vw;
    line-height: 1.075;
  }
`;

export const ParagraphWrapper = styled(motion.div)`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    align-items: center;
  }
`;

export const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.color.blueA};
  width: 23rem;
  line-height: 1.375;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${fluidType(12, 20)}/
  }
`;

export const PillGraphic = styled.span`
  height: 0.6em;
  width: 1.3em;
  display: inline-block;
  border-radius: 10rem;
  background-color: ${({ theme }) => theme.color.redA};
  position: relative;
  margin-left: 3rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    height: 0.5em;
    width: 1.1em;
  }
`;

export const PillCircle = styled.span`
  ${size('0.6em')};
  background-color: ${({ theme }) => theme.color.blueA};
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 10rem;
  transform: translateX(50%);

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${size('0.5em')};
  }

  &:after {
    ${size('100%')};
    content: '';
    position: absolute;
    border-radius: 10rem;
    top: 0;
    left: -50%;
    background-color: ${({ theme }) => theme.color.black};
  }
`;

export const ArrowWrapper = styled(motion.div)`
  ${fluidType(0, 30)}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 54%;
  max-width: 70rem;
  margin-top: 7.1rem;
`;

export const StyledArrow = styled(LargeArrow)`
  color: ${({ theme }) => theme.color.black};
  width: 4em;
  animation: ${bounce('y', 20, 90)} 2s ease-in-out infinite;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 5em;
  }
`;

export const EllipseGroup = styled.div`
  display: flex;
  width: 7.5em;
  height: 100%;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  align-items: flex-end;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    position: absolute;
    height: 5em;
    flex-direction: row;
    left: 0;

    &:nth-child(2) {
      flex-direction: row-reverse;
      bottom: 0;
      right: 0;
      left: auto;
      width: 5em;
      height: 7.5em;

      ${Ellipse} {
        background-color: ${({ theme }) => theme.color.redA};
        &:nth-child(5) {
          background-color: ${({ theme }) => theme.color.blueA};
        }
      }
    }
  }
`;
