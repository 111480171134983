import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledAnswersContainer = styled.div`
  gap: 6rem;
  display: flex;
  overflow: hidden;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: row;
  }
`;
