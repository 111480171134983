import { ReactElement, useState, useEffect, useRef, useMemo } from 'react';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import { clamp } from 'lodash';
import * as S from './QuestionSliderPreview.styles';
import { BarDots } from '../BarDots/BarDots';
import { scorebarIcons as icons } from 'components/atoms/Icon/Icon';
import { indexOfClosest } from 'util/closest';

interface QuestionSliderPreviewProps {
  maxScore: number;
}

const ScoreStepsAdapter = [0, 20, 40, 60, 80, 100];

export const QuestionSliderPreview = ({ maxScore }: QuestionSliderPreviewProps): ReactElement => {
  const [score, setScore] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const draggableRef = useRef<HTMLDivElement | null>(null);

  const onDrag = (endValuePx: number) => {
    const dragIconWidth = draggableRef.current?.offsetWidth || 0;
    const containerWidthPx = containerRef.current?.offsetWidth
      ? containerRef.current?.offsetWidth - dragIconWidth / 2
      : 0;

    const stepPx = containerWidthPx / maxScore;
    const scoreValuePt = Math.round(endValuePx / stepPx);
    setScore(clamp(scoreValuePt, 0, maxScore));

    const snapValuePx = stepPx * scoreValuePt;

    const snapPercentage = (snapValuePx * 100) / containerWidthPx;
    setPercentage(snapPercentage);

    return snapValuePx;
  };

  const killDraggable = () => {
    const draggable = draggableRef.current as HTMLDivElement;

    if (draggable) draggable.style.transform = '';

    Draggable.get(draggable)?.kill();
  };

  useEffect(() => {
    killDraggable();
    const draggable = draggableRef.current as HTMLElement;

    Draggable.create(draggable, {
      type: 'x',
      bounds: draggable.parentElement,
      edgeResistance: 1,
      throwProps: true,
      maxDuration: 0.5,
      snap: onDrag,
    });

    gsap.to(draggable, { x: draggable.offsetWidth / 2, duration: 0 });

    setScore(0);
    setPercentage(0);

    return () => {
      killDraggable();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxScore]);

  const currentIcon = useMemo(() => indexOfClosest(ScoreStepsAdapter, percentage), [percentage]);
  const Icon = icons[currentIcon];

  return (
    <S.StyledBarContainer>
      <S.StyledBar ref={containerRef}>
        <S.StyledBarBackground />
        <S.StyledBarIconInner color="cream" ref={draggableRef}>
          <Icon />
        </S.StyledBarIconInner>
        <BarDots dots={maxScore + 1} color="cream" padding="0.5rem" />
      </S.StyledBar>
      <S.StyledBarTotal>{score}</S.StyledBarTotal>
    </S.StyledBarContainer>
  );
};
