import hexToRgba from 'hex-to-rgba';

export const color = {
  cream: '#FFFDF9',
  white: '#fff',
  whiteB: hexToRgba('#FFFFFF', 0.5),
  black: '#000',
  blackB: hexToRgba('#000000', 0.5),
  blackC: 'rgba(0, 0, 0, 0.15)',
  blackD: hexToRgba('#000000', 0.4),
  greyA: '#999895',
  greyB: '#FAF8F4',
  greyC: 'rgba(0,0,0,0.11)',
  greyD: '#D9D9D9',
  greyE: hexToRgba('#D9D9D9', 0.4),
  greyF: hexToRgba('#999895', 0.3),
  greyG: hexToRgba('#999895', 0.2),
  blueA: '#0066B3',
  blueB: '#4D94CA',
  blueC: '#99C2E1',
  blueD: '#CCE0F0',
  blueE: '#e6f0f7',
  blueF: 'rgba(153, 194, 225, .3)',
  blueG: '#66A3D1',
  blueH: '#3385C2',
  blueI: '#B3D1E8',
  blueJ: '#80B2D9',
  redA: '#ED1C24',
  redB: '#F26066',
  redC: '#DC0B13',
  redD: '#FDE8E9',
  redE: '#BE0D14',
  redF: '#F14950',
  redG: '#F8A4A7',
  redH: '#F4777C',
  greenA: '#16b643',
};

export const theme = {
  color,
  boxShadow: {
    A: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    B: '1px 1px 5px rgba(0, 0, 0, 0.1)',
  },
  sitePaddings: {
    mobile: '3rem',
    desktop: '10.2rem',
  },
  utils: {
    // https://www.npmjs.com/package/hex-to-rgba
    hexToRgba,
  },
};

export type ThemeType = typeof theme;
export type ColorType = ThemeType['color'][keyof ThemeType['color']];
export type ColorKey = keyof ThemeType['color'];

// Overwrite styled-components DefaultTheme
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeType {}
}
