/* eslint-disable @typescript-eslint/naming-convention */
import { ReactElement, useCallback, useContext } from 'react';
import * as S from './AdminTable.styles';
import { getFormatedSuperadminTableData, getSuperadminDetails } from './AdminTable.data';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Plus } from 'components/atoms/Icon/Icon';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Separator } from 'components/atoms/Separator/Separator';
import { Table } from 'components/atoms/Table/Table';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { editModalNameEmailProps } from 'util/editModalNameEmailProps';
import { createSuperadminModal } from 'util/createSuperadminModal';
import { FormModalContext } from 'components/organisms/FormModal/FormModal';
import { Superadmin } from 'services/types/SuperadminTypes';
import { SuperadminService } from 'services/SuperadminService';

export interface AdminTableProps {
  adminUsers: Array<Superadmin>;
  deleteAdminUserByIndex: (index: number) => void;
  editAdminUser: (adminUser: Superadmin) => void;
  addAdminUser: (adminUser: Superadmin) => void;
}

export const AdminTable = ({
  adminUsers,
  addAdminUser,
  deleteAdminUserByIndex,
  editAdminUser,
}: AdminTableProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { useFormModal } = useContext(FormModalContext);
  const getTableData = useCallback(() => getFormatedSuperadminTableData(adminUsers), [adminUsers]);

  const DeleteUser = async (rowIndex: number) => {
    const { id, email } = getSuperadminDetails(getTableData(), rowIndex);

    const confirmed = await useConfirmModal({
      title: 'Delete user',
      subtitle: email,
      description: 'Are you sure you want to delete this user?',
    });
    if (!confirmed) return;

    await SuperadminService.deleteSuperadmin(id);
    deleteAdminUserByIndex(rowIndex);
  };

  const EditUser = async (rowIndex: number) => {
    const { id, name, email } = getSuperadminDetails(getTableData(), rowIndex);
    const formModalProps = editModalNameEmailProps(name, email);
    formModalProps.title = 'Edit Super Admin';

    const adminUserData = (await useFormModal(formModalProps)) as { email: string; name: string };
    if (!adminUserData) return;

    const adminUser = { ...adminUserData, id } as Superadmin;

    await SuperadminService.editSuperadmin(adminUser);
    editAdminUser(adminUser);
  };

  const AddUser = async () => {
    const formModalProps = createSuperadminModal(adminUsers);
    formModalProps.title = 'Add Super Admin';

    const adminUserData = (await useFormModal(formModalProps)) as {
      email_confirmation: string;
      email: string;
      name: string;
    };

    if (!adminUserData) return;

    const adminUser = await SuperadminService.createSuperadmin({
      email: adminUserData.email,
      name: adminUserData.name,
    });

    addAdminUser(adminUser);
  };

  return (
    <S.StyledAdminTable>
      <Flex container justifyContent="space-between" alignItems="center">
        <Heading type={HeadingType.H6}>SuperAdmin Management</Heading>
        <PrimaryButton $size="regular" $outline onClick={AddUser}>
          Add super admin
          <Plus />
        </PrimaryButton>
      </Flex>
      <Separator color="greyE" margin="2rem 0 3rem" />
      <Table
        thColor="blueA"
        data={getTableData()}
        maxHeight="35rem"
        actions={{ Edit: EditUser, Delete: DeleteUser }}
      />
    </S.StyledAdminTable>
  );
};
