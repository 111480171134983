import type { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './Intro.styles';
import { IntroItem } from './IntroItem/IntroItem';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Hide } from 'components/atoms/Hide/Hide';
import { MediaQuery } from 'styles/mediaQuery';
import { Feedback1, Feedback2, Feedback3 } from 'components/atoms/Icon/Icon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IntroProps {}

export const Intro = ({ ...props }: IntroProps): ReactElement => {
  const intl = useIntl();

  const items = [
    {
      paragraphs: [
        intl.formatMessage({
          description: 'Assessment Feedback - Item 1 - Paragraph',
          defaultMessage:
            'Congratulations! You are entering the next part of your 360º assessment: inviting assessors to give you feedback.',
        }),
      ],
    },
    {
      icon: Feedback1,
      title: intl.formatMessage({
        description: 'Assessment Feedback - Item 2 - Title',
        defaultMessage: 'Who to invite',
      }),
      paragraphs: [
        intl.formatMessage({
          description: 'Assessment Feedback - Item 2 - Paragraph',
          defaultMessage:
            'Invite at least 6 people who know you well to provide feedback on your leadership skills. We suggest that your respondents include 2 peers, 2 superiors, and 2 subordinates. However, you are free to invite friends, family members, and whoever you feel will provide you with the most helpful feedback.',
        }),
      ],
    },
    {
      icon: Feedback2,
      title: intl.formatMessage({
        description: 'Assessment Feedback - Item 3 - Title',
        defaultMessage: 'What to expect',
      }),
      paragraphs: [
        intl.formatMessage({
          description: 'Assessment Feedback - Item 3 - Paragraph',
          defaultMessage:
            'Your assessors will be asked to reflect on your leadership skills by answering the same questions you just answered yourself. They will also be given the opportunity to add additional comments. This is a crucial part of your learning and development process where you will receive comprehensive and unfiltered input.',
        }),
        intl.formatMessage({
          description: 'Assessment Feedback - Item 3 - List Title',
          defaultMessage: 'This will be included in your final report.',
        }),
      ],
    },
    {
      icon: Feedback3,
      title: intl.formatMessage({
        description: 'Assessment Feedback - Item 4 - Title',
        defaultMessage: 'A kind reminder',
      }),
      paragraphs: [
        intl.formatMessage({
          description: 'Assessment Feedback - Item 3 - Paragraph',
          defaultMessage:
            'Your assessors will be asked to give feedback anonymously. When inviting them, think about including diverse perspectives, e.g. both people that will focus on your strengths and those that might help to point out opportunities for growth.',
        }),
      ],
    },
  ];

  return (
    <S.StyledIntro {...props}>
      <Heading type={HeadingType.H2} margin={['0 0 1rem', [MediaQuery.MIN_1024, '0 0 2.4rem']]}>
        <FormattedMessage
          description="Assessment Feedback - Heading"
          defaultMessage="Survey completed"
        />
      </Heading>
      {items.map((item, index) => (
        /* eslint-disable react/no-array-index-key */
        <IntroItem key={`intro-it-${index}`} isLast={index === items.length - 1} {...item} />
      ))}
      {[...new Array(3).keys()].map((index) => (
        <Hide screen={MediaQuery.MAX_1023} key={`intro-bg-${index}`}>
          <BackgroundCircle
            color="redA"
            size="59rem"
            right="-39rem"
            top={`calc(${59 * index}rem - 45rem)`}
          />
        </Hide>
      ))}
    </S.StyledIntro>
  );
};
