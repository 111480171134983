import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';

export const StyledCohortUsersForm = styled(Flex)``;

export const StyledRegisterFormItemCheckbox = styled(RegisterFormItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: unset;
  margin: 0 6rem 0 4rem;

  input {
    margin-top: 1rem;
  }
`;
