/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  CustomerDashboardInitialStateProps,
  CustomerDashboardProps,
} from './customerDashboardTypes';
import { Customer, Unit, Subunit } from 'services/types/DashboardTypes';
import { ApiClient } from 'util/apiClient';
import { SelectItem } from 'components/molecules/Select/Select';

const getFilters = (filterData: Array<Unit | Subunit>): Array<SelectItem> => {
  return filterData.map((unit) => {
    return {
      value: unit.id.toString(),
      title: unit.description,
    };
  });
};

const fetchCustomerDashboardData = createAsyncThunk(
  '/customerDashboard/fetchDashboardData',
  async (id: string) => {
    const response = await ApiClient.get(`/customers/${id}`);
    return response.data as CustomerDashboardProps;
  },
);

const initialState: CustomerDashboardInitialStateProps = {
  customerData: null,
  customerUnitsSelectItem: [{ value: 0, title: 'Non-applicable' }],
  customerSubunitsSelectItem: [{ value: 0, title: 'Default' }],
  currentUnit: null,
  currentSubunit: null,
  currentCohort: null,
  currentSurvey: null,
  loading: false,
};

const customerDashboardSlice = createSlice({
  name: 'customerDashboard',
  initialState,
  reducers: {
    setUnitsFilter: (state, action) => {
      if (!state.customerData) return;
      const selectedUnitId = parseInt(action.payload, 10);
      const currentCustomer = current(state.customerData) as Customer;
      const selectedUnit = currentCustomer.organizational_units.find(
        (unit) => unit.id === selectedUnitId,
      );
      if (selectedUnit) {
        state.customerSubunitsSelectItem = getFilters(selectedUnit.organizational_sub_units);
        state.currentUnit = selectedUnit;
        state.currentSubunit = selectedUnit.organizational_sub_units[0];
      }
    },
    setCurrentSubunit: (state, action) => {
      if (!state.currentUnit) return;
      const selectedSubunitId = parseInt(action.payload, 10);
      const currentUnit = current(state.currentUnit) as Unit;
      const currentSubunit = currentUnit.organizational_sub_units.find(
        (subunit) => subunit.id === selectedSubunitId,
      );
      if (currentSubunit) state.currentSubunit = currentSubunit;
    },
    setCurrentCohort: (state, action) => {
      state.currentCohort = action.payload;
    },
    setCurrentSurvey: (state, action) => {
      state.currentSurvey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomerDashboardData.fulfilled, (state, action) => {
        const data = action.payload;
        state.customerData = data;
        // setting default filters
        state.customerUnitsSelectItem = getFilters(data.organizational_units);

        const subunits =
          data.organizational_units.length > 0
            ? data.organizational_units[0].organizational_sub_units
            : [];

        state.customerSubunitsSelectItem = getFilters(subunits);
        state.currentUnit = state.customerData.organizational_units[0];
        state.currentSubunit = subunits.length > 0 ? subunits[0] : null;
        state.loading = false;
      });
  },
});

export const customerDashboardActions = {
  ...customerDashboardSlice.actions,
  fetchCustomerDashboardData,
};

export default customerDashboardSlice.reducer;
