/* eslint-disable react-hooks/exhaustive-deps */
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { gsap } from 'gsap';
import { range } from 'lodash';
import { CSSProperties } from 'styled-components';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { BarDots } from '../BarDots/BarDots';
import { ScoreBarIcon1, ScoreBarIcon5 } from '../Icon/Icon';
import * as S from './Slider.styles';

gsap.registerPlugin(Draggable, InertiaPlugin);

interface SliderProps {
  value?: string | number;
  title: string;
  cantRate?: boolean;
  setRateValue?: (value: number, title: string) => void;
  dots?: number;
}

export const Slider = ({
  title,
  setRateValue,
  cantRate,
  value,
  dots,
  ...props
}: SliderProps): ReactElement => {
  const MAX_VALUES = dots || 6;
  const [percentage, setPercentage] = useState(0);
  const [lastValue, setLastValue] = useState(0);
  const [sliding, setSliding] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const progressNumberRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLButtonElement>(null);

  const cantRateStyle: CSSProperties = {
    filter: cantRate ? 'grayscale(1)' : '',
    pointerEvents: cantRate ? 'none' : 'all',
  };

  const currentNumber = Math.round(((MAX_VALUES - 1) * percentage) / 100);

  const setValue = (value: number) => {
    setPercentage(value);
    if (value > -1) {
      setLastValue(value);
    }
    if (setRateValue) {
      setRateValue(value, title);
    }
  };

  const setSnapPosition = (percent: number, tween?: boolean) => {
    if (sliderRef.current && thumbRef.current && progressRef.current) {
      const maxWidth = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth - 1;
      const snapPoints = [...range(0, maxWidth, maxWidth / (MAX_VALUES - 1)), maxWidth];

      const index = Math.round(((snapPoints.length - 1) * percent) / 100);

      if (tween) {
        gsap.to(thumbRef.current, { x: snapPoints[index], ease: 'expo.out' });
        gsap.to(progressRef.current, { scaleX: snapPoints[index] / maxWidth, ease: 'expo.out' });
      } else {
        gsap.set(thumbRef.current, { x: snapPoints[index] });
        gsap.set(progressRef.current, { scaleX: snapPoints[index] / maxWidth });
      }
    }
  };

  const getProgress = () => {
    const draggable = Draggable.get(thumbRef.current);
    return { p: draggable.x / draggable.maxX, x: draggable.x, max: draggable.maxX };
  };

  const setDraggableSnap = (endValue: number) => {
    if (sliderRef.current && thumbRef.current) {
      const maxWidth = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth - 1;
      const snapPoints = [...range(0, maxWidth, maxWidth / (MAX_VALUES - 1)), maxWidth];

      const snapPosition = snapPoints.reduce((previous: number, current: number) =>
        Math.abs(current - endValue) < Math.abs(previous - endValue) ? current : previous,
      );

      const draggable = Draggable.get(thumbRef.current);
      const progress = snapPosition / draggable.maxX;

      setValue(progress * 100);

      return snapPosition;
    }

    setValue(0);
    return 0;
  };

  const handleDragStart = () => {
    setSliding(true);
  };

  const handleThrowUpdate = () => {
    const progress = getProgress();
    gsap.set(progressRef.current, { scaleX: progress.p });
    gsap.set(progressNumberRef.current, { x: progress.x - 3 });
  };

  const handleDrag = () => {
    const progress = getProgress();
    if (progressRef.current) {
      gsap.set(progressRef.current, { scaleX: progress.p });
      gsap.set(progressNumberRef.current, { x: progress.x - 3 });
    }
    setPercentage(progress.p * 100);
  };

  const handleThrowComplete = () => {
    setSliding(false);
  };

  const handleDotClick = (index: number) => {
    if (sliderRef.current && thumbRef.current) {
      const maxWidth = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth - 1;
      const snapPoints = [...range(0, maxWidth, maxWidth / (MAX_VALUES - 1)), maxWidth];
      const position = snapPoints[index];
      const percent = (position / maxWidth) * 100;
      setSnapPosition(percent, true);
      setValue(percent);
    }
  };

  useEffect(() => {
    if (cantRate) {
      setValue(-1);
      setSnapPosition(0, true);
    } else if (lastValue !== -1 && value && value > -1) {
      setSnapPosition((value as number) || 0, true);
      setValue((value as number) || 0);
    } else {
      setSnapPosition(lastValue, true);
      setValue(lastValue);
    }
  }, [cantRate]);

  useEffect(() => {
    const draggable = Draggable.get(thumbRef.current);

    if (!draggable) {
      setValue((value as number) || 0);
    }

    if (thumbRef.current && sliderRef.current && !draggable) {
      const draggable = Draggable.create(thumbRef.current, {
        type: 'x',
        bounds: sliderRef.current,
        throwResistance: 2000,
        inertia: true,
        onThrowUpdate: handleThrowUpdate,
        onThrowComplete: handleThrowComplete,
        snap: setDraggableSnap,
        onDragStart: handleDragStart,
        onDrag: handleDrag,
      });

      if (draggable && value) {
        setSnapPosition(value as number);
      }
    }
  }, [thumbRef, sliderRef, value]);

  return (
    <div {...props} style={cantRateStyle}>
      <S.StyledSliderContainer ref={sliderRef}>
        <S.StyledProgress ref={progressRef} />

        {sliding && (
          <S.StyledProgressNumber ref={progressNumberRef}>{currentNumber}</S.StyledProgressNumber>
        )}

        <BarDots onClick={handleDotClick} progress={percentage} dots={MAX_VALUES} color="blueJ" />

        <S.StyledCustomSlider>
          <S.StyledSliderThumb ref={thumbRef}></S.StyledSliderThumb>
        </S.StyledCustomSlider>
      </S.StyledSliderContainer>
      <S.StyledBottomFlex container alignItems="center" justifyContent="space-between">
        <ScoreBarIcon1 />
        <ScoreBarIcon5 />
      </S.StyledBottomFlex>
    </div>
  );
};
