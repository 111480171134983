import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';

export const StyledCreateCohortForm = styled(Flex)`
  position: relative;
  margin-top: 5rem;

  .delete-cohort {
    position: absolute;
    right: 0;
  }
`;

export const StyledDeleteCohort = styled.button``;
