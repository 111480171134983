/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useEffect, useState } from 'react';
import { CreateCohortForm } from '../CreateCohortForm/CreateCohortForm';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Tabs } from 'components/atoms/Tabs/Tabs';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { Participant } from 'model/Participant';
import { Cohort } from 'model/Cohort';
import { OrganizationUnit } from 'model/OrganizationUnit';
import { OrganizationSubunit } from 'model/OrganizationSubunit';
import { Survey } from 'model/Survey';

interface CreateCohortProps extends RegisteredFormItem {
  subunitIndex: number;
  isSubunit: boolean;
  name?: string;
  unitIndex?: number;
  participants: Array<Participant>;
  cohorts: Array<Cohort>;
  surveys: Array<{ id: number; name: string }>;
  isEditMode?: boolean;
  initialEditCohorts: Array<number>;
  handleAddCohort: (unitId?: number, subunitId?: number) => void;
  handleDeleteCohort: (unitId: number, subunitId: number, cohortId: number) => void;
  handleCohortChange: (unitId: number, subunitId: number, cohortId: number, name: string) => void;
  handleSurveyChange: (unitId: number, subunitId: number, cohortId: number, survey: Survey) => void;
  subunit: OrganizationSubunit;
  unit: OrganizationUnit;
  handleParticipantChange: (
    unitId: number,
    subunitId: number,
    cohortId: number,
    newParticipantsList: Array<Participant>,
  ) => void;
}

export const CreateCohort = ({
  register,
  formMethods,
  errors,
  unitIndex,
  subunitIndex,
  isSubunit,
  name,
  participants,
  surveys,
  cohorts,
  isEditMode,
  initialEditCohorts,
  handleAddCohort,
  handleDeleteCohort,
  subunit,
  unit,
  handleParticipantChange,
  handleCohortChange,
  handleSurveyChange,
}: CreateCohortProps): ReactElement => {
  const [activeCohortIndex, setActiveCohortIndex] = useState(0);
  const handleDeleteTab = (cohortId: number) => {
    if (cohortId) handleDeleteCohort(unit.id, subunit.id, cohortId);
  };

  useEffect(() => {
    setActiveCohortIndex(cohorts.length - 1);
  }, [cohorts]);

  return (
    <>
      <Heading type={HeadingType.H6} margin="5rem 0 4rem">
        Create Cohort
      </Heading>
      <TabsContainer activeTab={activeCohortIndex} setActiveTab={setActiveCohortIndex}>
        <Tabs
          color="blueA"
          tabs={cohorts.map((item) => item.name || '')}
          cta={{
            label: 'add cohort',
            action: () => {
              handleAddCohort(unit.id, subunit.id);
              setActiveCohortIndex(cohorts.length);
            },
          }}
        />

        <div>
          {cohorts.map((cohort, index) => (
            <CreateCohortForm
              name={`${name}${cohort.name}.cohorts[${index}]`}
              key={`${unitIndex}-${subunitIndex}-${index}`}
              register={register}
              formMethods={formMethods}
              errors={errors}
              cohort={cohort}
              handleCohortChange={handleCohortChange}
              handleDeleteTab={handleDeleteTab}
              handleParticipantChange={handleParticipantChange}
              handleSurveyChange={handleSurveyChange}
              isSubunit={isSubunit}
              subunitId={subunit.id}
              cohortIndex={index}
              participants={participants}
              surveys={surveys}
              unitId={unit.id}
              isEditMode={isEditMode}
              initialEditCohorts={initialEditCohorts}
            />
          ))}
        </div>
      </TabsContainer>
    </>
  );
};
