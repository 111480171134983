import styled, { css } from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export interface RadioStyleProps {
  mobileType?: 'box' | 'row';
}

export const StyledRadioContainer = styled.div<RadioStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ mobileType, theme }) =>
    mobileType &&
    css`
      @media ${respondTo(MediaQuery.MAX_767)} {
        ${mobileType === 'box' &&
        `
          justify-content: center;
          width: 100%;
          height: 14.8rem;
          border-radius: 0.8rem;
          background: ${theme.color.greyB};
        `}

        ${mobileType === 'row' &&
        `
          flex-direction: row;
        `}
      }
    `}
`;

export const StyledRadio = styled.input<RadioStyleProps>`
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.blueA};
  border: 1px solid currentColor;
  position: relative;
  cursor: pointer;

  ${({ mobileType }) =>
    mobileType &&
    css`
      @media ${respondTo(MediaQuery.MAX_767)} {
        width: 2rem;
        height: 2rem;
      }
    `}

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    width: 0.4rem;
    height: 0.4rem;
    background: currentColor;

    ${({ mobileType }) =>
      mobileType &&
      css`
        @media ${respondTo(MediaQuery.MAX_767)} {
          width: 0.7rem;
          height: 0.7rem;
        }
      `}
  }

  &[checked] {
    &::after {
      opacity: 1;
    }
  }
`;
