import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Form } from 'components/molecules/Form/Form';

export const StyledFormContainer = styled(Form)`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    position: relative;

    & > button {
      position: absolute;
      right: 0;
      top: calc(100% + 2rem);
    }
  }

  & > div {
    @media ${respondTo(MediaQuery.MIN_1024)} {
      margin-bottom: 0;
      flex-basis: 20%;
      min-width: 20%;
    }
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    display: flex;
    align-items: flex-end;
    gap: 4rem;
    margin: auto 2rem;
  }
`;
