import styled from 'styled-components';
import { Text } from 'components/atoms/Text/Text';

export const StyledShareContainer = styled.div`
  background: ${({ theme }) => theme.color.greyE};
  border: 0.1rem solid ${({ theme }) => theme.color.greyD};
  padding: 1rem;
  border-radius: 0.8rem;
`;

export const StyledText = styled(Text)`
  margin-top: 16px;
`;
