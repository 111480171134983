import { defineMessages } from 'react-intl';

export const carouselItems = defineMessages({
  objectiveOneTitle: {
    description: 'Onboarding - Objectives - Objective 1 - Title',
    defaultMessage: 'Assess yourself',
  },
  objectiveOneContent: {
    description: 'Onboarding - Objectives - Objective 1 - Content',
    defaultMessage: `
    <p>Learn about your strengths and weaknesses by answering questions about your leadership.</p>
     <h6>You will explore:</h6>
      <ul>
        <li>What are your gifts</li>
        <li>What are your growth opportunities</li>
        <li>Where do you want to develop</li>
      </ul>
    `,
  },
  objectiveTwoTitle: {
    description: 'Onboarding - Objectives - Objective 2 - Title',
    defaultMessage: 'Ask for feedback',
  },
  objectiveTwoContent: {
    description: 'Onboarding - Objectives - Objective 2 - Content',
    defaultMessage: `
    <p>Identify people that you would like to get feedback from.</p>
      <h6>The tool will help you to:</h6>
        <ul>
          <li>Invite them to give feedback</li>
          <li>Check their progress</li>
          <li>Follow-up by sending reminders</li>
        </ul>
      `,
  },
  objectiveThreeTitle: {
    description: 'Onboarding - Objectives - Objective 3 - Title',
    defaultMessage: 'Set objectives',
  },
  objectiveThreeContent: {
    description: 'Onboarding - Objectives - Objective 3 - Content',
    defaultMessage: `
    <p>Based on your 360º assessment, decide which areas you want to develop.</p>
    <h6>You will:</h6>
      <ul>
        <li>Determine your area of focus</li>
        <li>Make your objective specific</li>
        <li>Choose a set of challenges to get you going</li>
      </ul>
      `,
  },
  objectiveFourTitle: {
    description: 'Onboarding - Objectives - Objective 4 - Title',
    defaultMessage: 'Complete missions',
  },
  objectiveFourContent: {
    description: 'Onboarding - Objectives - Objective 4 - Content',
    defaultMessage: `
    <p>You will receive regular challenges linked to the objective you set. </p>
      <h6>Complete these challenges to:</h6>
        <ul>
          <li>To keep your objectives top of mind</li>
          <li>Practice with new behaviors</li>
          <li>Grow your leadership</li>
        </ul>
      `,
  },
});
