import styled from 'styled-components';
import { Separator } from 'components/atoms/Separator/Separator';
import { Input } from 'components/atoms/Input/Input';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { zIndex } from 'styles/zIndex';

export const StyledManagementTable = styled.div`
  position: relative;
  z-index: ${zIndex('default')};
`;

export const StyledSeparator = styled(Separator)`
  margin: 2.6rem -3rem 3rem;
  width: calc(100% + 6rem);
`;

export const InputWrapper = styled.div`
  flex: 0.8;
  max-width: 25rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex: 0.4;
  }
`;

export const StyledSpan = styled.span`
  position: relative;
  bottom: 0.6rem;
  left: 0.2rem;
  font-size: 1.3rem;
`;

export const StyledSearchInput = styled(Input)`
  padding-right: 4rem;
  border: solid 1px ${({ theme }) => theme.color.blueA};
  color: ${({ theme }) => theme.color.blueA};

  &::placeholder {
    color: ${({ theme }) => theme.color.blueC};
    text-transform: uppercase;
  }
`;
