import type { ReactElement } from 'react';
import * as S from './BarDots.styles';
import { ColorKey } from 'styles/theme/default';

interface BarDotsProps {
  dots: number;
  color: ColorKey;
  padding?: string;
  progress?: number;
  onClick?: (index: number) => void;
}

export const BarDots = ({
  dots,
  color,
  padding,
  progress,
  onClick,
}: BarDotsProps): ReactElement => {
  const handleClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };
  return (
    <S.StyledBarDotsWrapper color={color} padding={padding}>
      {[...new Array(dots).keys()].map((dotIndex) => (
        <S.StyledBarDots
          onClick={() => handleClick(dotIndex)}
          $hidden={progress !== undefined && progress > (dotIndex * 100) / (dots - 1)}
          key={`dot-${dotIndex}`}
        />
      ))}
    </S.StyledBarDotsWrapper>
  );
};
