import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Message = styled.p`
  text-transform: uppercase;

  &:first-of-type {
    margin-bottom: 3.5rem;
  }
`;
