import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as S from './EmptyFeedbackCard.styles';
import { slideFade } from 'util/motionTransitions';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Text } from 'components/atoms/Text/Text';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Path } from 'routes/Path';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyFeedbackCardProps {
  cohortSurveyId?: number;
}

export const EmptyFeedbackCard = ({
  cohortSurveyId,
  ...props
}: EmptyFeedbackCardProps): ReactElement => {
  const { push } = useHistory();
  const handleStartAssessment = () => {
    if (!cohortSurveyId) return;
    push(`${Path.Assessment}/${cohortSurveyId}`);
  };

  return (
    <S.StyledEmptyFeedbackCard {...props} {...slideFade()} variant="blue">
      <S.StyledContent>
        <Heading type={HeadingType.H6}>
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Heading"
            defaultMessage="No Results Yet"
          />
        </Heading>
        <Paragraph color="currentColor" margin="1rem 0 3rem">
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Paragraph"
            defaultMessage="There are still some assessors that haven’t completed the assessment. You can always send
            them a friendly reminder"
          />
        </Paragraph>
        <Text type="bold" color="inherit">
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Note"
            defaultMessage="At least 3 assessors must finish the assessment in order for you to receive your results."
          />
        </Text>
        <PrimaryButton onClick={handleStartAssessment} $backgroundColor="white" $color="blueA">
          <FormattedMessage
            description="User Dashboard - Retake Assessment - Cta"
            defaultMessage="Retake Assessment"
          />
        </PrimaryButton>
      </S.StyledContent>
    </S.StyledEmptyFeedbackCard>
  );
};
