import styled from 'styled-components';
import { Slider } from 'components/atoms/Slider/Slider';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledRate = styled.div``;

export const StyledSlider = styled(Slider)`
  width: 100%;
  margin: 0 0 3rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    width: 70%;
    margin: 2rem 5.1rem 0 0;
  }
`;
