import { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../Login.locale';
import { Form } from '../../../../molecules/Form/Form';
import { newPasswordFormSchema } from '../../../../molecules/Form/FormSchemas';
import { LoginFormType } from '../../Login.types';
import { StyledPrimaryButton, StyledRegisterFormItem } from '../LoginForm/LoginForm.styles';
import { StyledCopy } from './NewPasswordForm.styles';
import { ButtonWrapper } from '../ResetForm/ResetForm.styles';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { Input } from 'components/atoms/Input/Input';
import { ApiClient } from 'util/apiClient';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { Path } from 'routes/Path';

// TODO: Validate confirm password input field

interface NewPasswordFormProps {
  setActiveForm: React.Dispatch<React.SetStateAction<LoginFormType>>;
  form?: LoginFormType;
}

export const NewPasswordForm = ({ setActiveForm, form }: NewPasswordFormProps): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const onFormSuccess = async (formData: FieldValues) => {
    const token = new URLSearchParams(search).get('token');
    const resetPasswordBody = {
      email: formData.enter_email,
      password: formData.new_password,
      password_confirmation: formData.confirm_password,
      token,
    };
    ApiClient.post('/reset-password', resetPasswordBody)
      .then(() => {
        dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
        window.location.pathname = Path.Login;
      })
      .catch(() => {
        dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
      });
  };

  return (
    <Form onSuccess={onFormSuccess} schema={newPasswordFormSchema}>
      <StyledRegisterFormItem
        register
        registerErrors="required"
        name="enter_email"
        label={intl.formatMessage(messages.enterEmailAdress)}
        size="Medium"
      >
        <Input hasRef placeholder={intl.formatMessage(messages.enterEmailAdressPlaceholder)} />
      </StyledRegisterFormItem>
      <StyledRegisterFormItem
        register
        registerErrors="required"
        name="new_password"
        label={intl.formatMessage(messages.newPasswordLabel)}
        size="Medium"
      >
        <Input hasRef placeholder={intl.formatMessage(messages.enterNewPassword)} type="password" />
      </StyledRegisterFormItem>
      <StyledRegisterFormItem
        register
        registerErrors="required"
        name="confirm_password"
        label={intl.formatMessage(messages.confirmPasswordLabel)}
        size="Medium"
      >
        <Input
          hasRef
          placeholder={intl.formatMessage(messages.confirmNewPassword)}
          type="password"
        />
      </StyledRegisterFormItem>
      <StyledCopy>
        <span>
          {form === LoginFormType.NewPasswordForm
            ? intl.formatMessage(messages.resetPasswordDescription)
            : intl.formatMessage(messages.createPasswordDescription)}
        </span>
      </StyledCopy>
      <ButtonWrapper>
        <StyledPrimaryButton type="submit" $mobileSize="regular" $backgroundColor="redA">
          <span>
            {form === LoginFormType.NewPasswordForm
              ? intl.formatMessage(messages.resetPasswordButton)
              : intl.formatMessage(messages.createPasswordButton)}
          </span>
        </StyledPrimaryButton>
        {form === LoginFormType.NewPasswordForm && (
          <SecondaryButton
            type="button"
            color="blueA"
            onClick={() => setActiveForm(LoginFormType.LoginForm)}
          >
            <span>{intl.formatMessage(messages.cancelButton)}</span>
          </SecondaryButton>
        )}
      </ButtonWrapper>
    </Form>
  );
};
