import styled, { css } from 'styled-components';
import { size } from 'styles/helpers/size';
import { ColorKey } from 'styles/theme/default';

interface BarDotsStyleProps {
  color: ColorKey;
  padding?: string;
}

export const StyledBarDots = styled.div<{ $hidden?: boolean }>`
  position: relative;
  ${({ $hidden }) => $hidden && 'visibility: hidden;'}
  cursor: pointer;
  transform: translateX(50%);

  &:before {
    ${size('2rem')}
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    ${size('0.5rem')}
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledBarDotsWrapper = styled.div<BarDotsStyleProps>`
  ${size('100%')}
  ${({ padding }) => `padding: 0 ${padding}`};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${StyledBarDots} {
    ${({ color, theme }) => css`
      &:after {
        background: ${theme.color[color]};
      }
    `}
  }
`;
