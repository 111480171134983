import styled from 'styled-components';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-left: 3rem;

  &:first-of-type {
    margin: 0;
  }
`;
