import styled from 'styled-components';
import { Radio } from '../Radio/Radio';
import { typeStyle } from 'styles/typeStyle';

export const PillRadio = styled(Radio)`
  border: solid 1px ${({ theme }) => theme.color.blueA};
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  border-radius: 2px;
  color: ${({ theme }) => theme.color.blueA};

  label {
    ${typeStyle.uppercase};
    color: ${({ theme }) => theme.color.blueA};
    padding: 0.8rem 1.5rem;
    padding-right: 1rem;
    margin: 0;
    cursor: pointer;
    user-select: none;
  }
`;
