// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { ReactElement, RefCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import * as S from './ManagementTable.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';
import { Table, TableProps } from 'components/atoms/Table/Table';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Text } from 'components/atoms/Text/Text';
import { AccordionHeading } from 'components/molecules/AccordionHeading/AccordionHeading';

interface ManagementTableProps extends TableProps, RegisterFormItemChild {
  isRemove?: boolean;
  outsideComponent?: string;
  accordionRef?: RefCallback<HTMLElement>;
  setFilterQuery?: (value: string) => void;
}

export const ManagementTable = ({
  data,
  register,
  isAddQuestions,
  outsideComponent,
  shouldDisableItem,
  accordionRef,
  setFilterQuery,
  ...props
}: ManagementTableProps): ReactElement => {
  const [tableData, setTableData] = useState(data);

  const isCreateQuestion = outsideComponent === 'add-questions';

  const isSearchComponent = outsideComponent === 'search-questions';

  const isBlue = isCreateQuestion || isSearchComponent ? 'blueA' : 'greyA';

  const [openIndex, setOpenIndex] = useState<boolean>(true);

  const handleSearchInputChange = debounce((event) => {
    const { value } = event.target;
    setFilterQuery?.(value);
  }, 66);

  useEffect(() => {
    setTableData(data);
  }, [data, outsideComponent]);

  return (
    <S.StyledManagementTable>
      <CardContainer isCreateQuestion={isCreateQuestion}>
        <Flex container justifyContent="space-between" alignItems="center">
          <S.InputWrapper>
            {!isCreateQuestion ? (
              <S.StyledSearchInput
                handleInputChange={handleSearchInputChange}
                placeholder="Search"
                name="search"
                icon="Search"
              />
            ) : (
              <Flex container justifyContent="space-between">
                {' '}
                <Text color="blueA">
                  Questions <S.StyledSpan>({data.rows.length})</S.StyledSpan>{' '}
                </Text>
                <div style={{ position: 'absolute', right: '45px' }}>
                  <AccordionHeading
                    isOpen={openIndex}
                    handleToggleOpen={() => setOpenIndex(!openIndex)}
                  />
                </div>
              </Flex>
            )}
          </S.InputWrapper>
          {!isCreateQuestion && (
            <UppercaseText color="greyA">Total entries: {data.rows.length}</UppercaseText>
          )}
        </Flex>
        <S.StyledSeparator isCreateQuestion={isCreateQuestion} />
        {openIndex && (
          <Table
            key={tableData.rows.length}
            thColor={isBlue}
            data={tableData}
            {...props}
            isAddQuestions
            shouldDisableItem={shouldDisableItem}
          />
        )}
      </CardContainer>
    </S.StyledManagementTable>
  );
};
