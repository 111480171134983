import styled from 'styled-components';
import { MediaQuery } from 'styles/mediaQuery';
import { respondTo } from 'styles/helpers/respondTo';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

export const StyledScoreBarFlex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  justify-content: space-around;
  padding-right: 4px;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-bottom: 8rem;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: 1.8rem;
`;
