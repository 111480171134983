// PROFILE ACHIEVEMENT ICONS
import { ReactComponent as AlmostPuzzle } from './svg/almost-puzzle.svg';
import { ReactComponent as Asteroid } from './svg/asteroid.svg';
import { ReactComponent as Bulb } from './svg/bulb.svg';
import { ReactComponent as Certificate } from './svg/certificate.svg';
import { ReactComponent as Clock } from './svg/clock.svg';
import { ReactComponent as Coffee } from './svg/coffee.svg';
import { ReactComponent as Cup } from './svg/cup.svg';
import { ReactComponent as Flag } from './svg/flag.svg';
import { ReactComponent as HalfPuzzle } from './svg/half-puzzle.svg';
import { ReactComponent as Laptop } from './svg/laptop.svg';
import { ReactComponent as Pot } from './svg/pot.svg';
import { ReactComponent as Puzzle } from './svg/puzzle.svg';
import { ReactComponent as Rocket } from './svg/rocket.svg';
import { ReactComponent as Mountain } from './svg/mountain.svg';
import { ReactComponent as ScoreBarIcon0 } from './svg/scorebar-0.svg';
import { ReactComponent as ScoreBarIcon1 } from './svg/scorebar-1.svg';
import { ReactComponent as ScoreBarIcon2 } from './svg/scorebar-2.svg';
import { ReactComponent as ScoreBarIcon3 } from './svg/scorebar-3.svg';
import { ReactComponent as ScoreBarIcon4 } from './svg/scorebar-4.svg';
import { ReactComponent as ScoreBarIcon5 } from './svg/scorebar-5.svg';

export { ReactComponent as Cross } from './svg/cross.svg';
export { ReactComponent as ArrowDown } from './svg/arrow-down.svg';
export { ReactComponent as ArrowGrotesk } from './svg/arrow-grotesk.svg';
export { ReactComponent as Add } from './svg/add.svg';
export { ReactComponent as Plus } from './svg/plus.svg';
export { ReactComponent as Logo } from './svg/logo.svg';
export { ReactComponent as Profile } from './svg/profile.svg';
export { ReactComponent as Bell } from './svg/bell.svg';
export { ReactComponent as Expand } from './svg/expand.svg';
export { ReactComponent as Hamburguer } from './svg/hamburguer.svg';
export { ReactComponent as LargeArrow } from './svg/large-arrow.svg';
export { ReactComponent as ElementsCursor } from './svg/elements-cursor.svg';
export { ReactComponent as Chevron } from './svg/chevron.svg';
export { ReactComponent as CheckList } from './svg/check-list.svg';
export { ReactComponent as Play } from './svg/video-play.svg';
export { ReactComponent as ArrowExternal } from './svg/arrow-external.svg';
export { ReactComponent as Camera } from './svg/camera.svg';
export { ReactComponent as Download } from './svg/download.svg';
export { ReactComponent as Delete } from './svg/delete.svg';
export { ReactComponent as Notification } from './svg/notification.svg';
export { ReactComponent as Copy } from './svg/copy.svg';
export { ReactComponent as Edit } from './svg/edit.svg';
export { ReactComponent as EditLarge } from './svg/edit-large.svg';
export { ReactComponent as Calendar } from './svg/calendar.svg';
export { ReactComponent as Mountain } from './svg/mountain.svg';
export { ReactComponent as Certificate } from './svg/certificate.svg';
export { ReactComponent as Minus } from './svg/minus.svg';
export { ReactComponent as Thumb } from './svg/thumb.svg';
export { ReactComponent as Trash } from './svg/trash.svg';
export { ReactComponent as Feedback1 } from './svg/feedback-1.svg';
export { ReactComponent as Feedback2 } from './svg/feedback-2.svg';
export { ReactComponent as Feedback3 } from './svg/feedback-3.svg';
export { ReactComponent as ScoreBarIcon1 } from './svg/scorebar-1.svg';
export { ReactComponent as ScoreBarIcon5 } from './svg/scorebar-5.svg';
export { ReactComponent as Search } from './svg/search.svg';
export { ReactComponent as Symbol } from './svg/symbol.svg';
export { ReactComponent as THNK } from './svg/thnk.svg';
export { ReactComponent as Upload } from './svg/upload.svg';
export { ReactComponent as Duplicate } from './svg/duplicate.svg';
export { ReactComponent as AddNew } from './svg/open.svg';
export { ReactComponent as Remove } from './svg/close.svg';
export { ReactComponent as AddOrRemove } from './svg/close.svg';
export { ReactComponent as RemoveOrAdd } from './svg/close.svg';
export { ReactComponent as Eye } from './svg/eye.svg';
export { ReactComponent as EyeOff } from './svg/eye-off.svg';

export const achievementIcons = {
  AlmostPuzzle,
  Asteroid,
  Bulb,
  Certificate,
  Clock,
  Coffee,
  Cup,
  Flag,
  HalfPuzzle,
  Laptop,
  Pot,
  Puzzle,
  Rocket,
  Mountain,
};

export const scorebarIcons = [
  ScoreBarIcon0,
  ScoreBarIcon1,
  ScoreBarIcon2,
  ScoreBarIcon3,
  ScoreBarIcon4,
  ScoreBarIcon5,
];
