import { ReactElement, useMemo } from 'react';
import * as S from './ScoreBar.styles';
import { scorebarIcons as icons } from 'components/atoms/Icon/Icon';
import { BarDots } from 'components/atoms/BarDots/BarDots';

interface ScoreBarProps {
  score?: number;
  barDots?: number;
  variant?: 'white' | 'blue';
}

export const ScoreBar = ({
  score = 1,
  barDots = 6,
  variant = 'white',
}: ScoreBarProps): ReactElement => {
  const roundedScore = useMemo(() => Math.round(score), [score]);
  const secondaryColor = variant === 'white' ? 'blueA' : 'cream';
  const iconIndex = Math.round((roundedScore / 100) * (Object.keys(icons).length - 1));
  const Icon = icons[iconIndex];
  const noScore = roundedScore === -1;

  return (
    <S.StyledBarContainer $grey={noScore}>
      <S.StyledBar>
        <S.StyledBarBackground>
          <S.StyledBarProgress style={{ transform: `scaleX(${roundedScore / 100})` }} />
        </S.StyledBarBackground>
        {!noScore && (
          <S.StyledBarIconContainer style={{ transform: `translateX(${roundedScore}%)` }}>
            <S.StyledBarIconInner color={secondaryColor}>
              <Icon />
            </S.StyledBarIconInner>
          </S.StyledBarIconContainer>
        )}
        <BarDots dots={barDots} color={secondaryColor} padding="0.5rem" />
      </S.StyledBar>
      <S.StyledBarTotal>{noScore ? 'N/A' : roundedScore}</S.StyledBarTotal>
    </S.StyledBarContainer>
  );
};
