import { motion } from 'framer-motion';
import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledAssessment = styled.div`
  padding: 4rem 0 17rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    padding: 8.7rem 0 17rem;
  }
`;

export const StyledQuestion = styled(motion.div)``;
