import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';
import { RootState } from 'redux/store';

interface CurrentParticipantIndex {
  currentParticipantIndex: number;
}

/**
 * @param useUserIndex true if you want to use the index of the current logged in user
 * @returns CurrentParticipantIndex
 */
export const useCurrentUserIndex = (useUserIndex: boolean): CurrentParticipantIndex => {
  const { cohorts, currentCohortIndex, currentParticipantIndex } = useSelector(
    (state: RootState) => state.dashboard,
  );
  const { profileId } = useSelector((state: RootState) => state.auth);
  const { participants } = cohorts[currentCohortIndex].cohort_survey;
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileId && useUserIndex) {
      const currentUserIndex = participants.findIndex((p) => p.id === profileId);
      dispatch(dashboardActions.setCurrentParticipantIndex(currentUserIndex));
    } else {
      dispatch(dashboardActions.setCurrentParticipantIndex(0));
    }
  }, [profileId, participants, dispatch, useUserIndex]);

  return {
    currentParticipantIndex,
  };
};
