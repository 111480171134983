import styled from 'styled-components';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { size } from 'styles/helpers/size';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';

const animationTransition = `${Ease.EaseOutCubic} 500ms`;

export const StyledBarIconInner = styled.div<BarIconStyleProps>`
  ${size('4rem')}
  position: absolute;
  border-radius: 50%;
  background: currentColor;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform ${animationTransition};

  svg {
    ${size('3.5rem')}
    color: ${({ color, theme }) => theme.color[color]};
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${size('4.5rem')}
  }
`;

export const StyledScoreBar = styled.div``;

export const StyledBarContainer = styled.div<{ $grey?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3.4rem;
  justify-content: space-between;
  gap: 2rem;
  filter: ${({ $grey }) => ($grey ? 'grayscale(1)' : 'none')};
`;

export const StyledBar = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledBarBackground = styled.div`
  height: 1rem;
  border-radius: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.color.blueF};
  overflow: hidden;
`;

export const StyledBarProgress = styled.div`
  background: currentColor;
  height: 100%;
  width: 100%;
  transition: transform ${animationTransition};
  transform-origin: left;
`;

interface BarIconStyleProps {
  color: ColorKey;
}

export const StyledBarIconContainer = styled.div`
  position: absolute;
  width: calc(100% - 1rem);
  height: 100%;
  top: 0;
  left: 0.5rem;
  z-index: 1;
  transition: transform ${animationTransition};
`;

export const StyledBarTotal = styled.div`
  color: ${({ theme }) => theme.color.blueC};
  font-size: 1.4rem;
  min-width: 1rem;
  text-align: right;
`;
