/* eslint-disable no-console */
import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import * as S from './Rate.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Radio } from 'components/atoms/Radio/Radio';
import { MediaQuery } from 'styles/mediaQuery';

export interface RateProps {
  name: string;
  title: string;
  description: string;
  value?: number | string;
  setRateValue?: (value: number, title: string) => void;
  dots: number;
}

export const Rate = ({
  name,
  title,
  description,
  value,
  setRateValue,
  dots,
}: RateProps): ReactElement => {
  const [cantrate, setCantRate] = useState(false);

  const intl = useIntl();

  return (
    <Flex container alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Flex flexBasis={['100%', [MediaQuery.MIN_768, '30%']]}>
        <Heading type={HeadingType.H4}>{name}</Heading>
        {description && (
          <Paragraph margin={['1rem 0 3rem', [MediaQuery.MIN_768, '1.7rem 0 0']]}>
            {description}
          </Paragraph>
        )}
      </Flex>
      <Flex
        container
        flexBasis={['100%', [MediaQuery.MIN_768, '70%']]}
        justifyContent={[[MediaQuery.MIN_768, 'flex-end']]}
        alignItems="center"
        flexWrap="wrap"
      >
        <S.StyledSlider
          value={value}
          title={title}
          setRateValue={setRateValue}
          cantRate={cantrate}
          dots={dots}
        />
        <Radio
          id={name}
          name={name}
          onClick={() => setCantRate(!cantrate)}
          defaultChecked={cantrate}
          label={intl.formatMessage({
            description: "Assessment - Rate - Can't Rate Copy",
            defaultMessage: 'Cannot rate',
          })}
          mobileType="row"
        />
      </Flex>
    </Flex>
  );
};
